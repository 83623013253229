import classnames from 'classnames'
import { Placeholder } from '../../../widgets/Placeholder'
import { Button } from 'inferno-bootstrap'
import './ImagePromo.scss'

export const ImagePromo = ({src, reverse = false, inverse = false, children}, {router}) => {
  return (
    <div className={classnames("ImagePromo", {reverse, inverse})}>
      <img src={src} />
      <div className="body">
        {children}
        <Button color="primary" onClick={() => router.history.push('/product')}>Make your mark</Button>
      </div>
    </div>
  )
}