import { globalRegistry, createObjectPrototype, Adapter, Utility } from 'component-registry';
import { Workflow } from 'influence-workflow';
import { IWorkflow, IWorkflowLookup, IWorkflowState } from 'influence-interfaces/workflow';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IPromoCodeWorkflow } from '../../interfaces/workflows';
var _IPromoCodeWorkflow$s = IPromoCodeWorkflow.schema,
    statePropName = _IPromoCodeWorkflow$s.statePropName,
    workflowStates = _IPromoCodeWorkflow$s.workflowStates;
export var PromoCodeWorkflow = createObjectPrototype({
  implements: [IPromoCodeWorkflow],
  extends: [Workflow],
  constructor: function constructor(params, _ref) {
    var defaultState = _ref.defaultState;

    this._IWorkflow.constructor.call(this, params);

    if (this._workflows[statePropName] === undefined) {
      this._workflows[statePropName] = defaultState;
    }
  }
});
var PromoCodeWorkflowPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'PromoCodeWorkflow',
  getPrototype: function getPrototype() {
    return PromoCodeWorkflow;
  },
  getObject: function getObject() {
    return new PromoCodeWorkflow();
  }
});
var PromoCodeWorkflowLookup = new Utility({
  implements: IWorkflowLookup,
  name: 'PromoCodeWorkflow',
  getInterface: function getInterface() {
    return IPromoCodeWorkflow;
  }
});
var PromoCodeWorkflowState = new Adapter({
  implements: IWorkflowState,
  adapts: IPromoCodeWorkflow,
  getState: function getState() {
    if (this.context._workflows) {
      var tmpStates = this.getAllStates();
      var tmpName = this.context._workflows[statePropName];
      var tmpState = tmpStates[tmpName];
      return tmpState && {
        state: tmpName,
        title: tmpState.title
      };
    }
  },
  setState: function setState(newState, currPrincipal) {
    var tmp = this.getAvailableStates(currPrincipal);

    if (tmp.hasOwnProperty(newState)) {
      this.context._workflows[statePropName] = newState;
    } else {// TODO: Throw WorkflowStateError
    }
  },
  getAllStates: function getAllStates() {
    return workflowStates;
  },
  getAvailableStates: function getAvailableStates(currPrincipal) {
    return workflowStates;
  }
});