import { Link } from 'inferno-router'
import './SitePageFooter.scss'

function PageFooterContainer (props) {
  return (
    <div className="PageFooterContainer">
      {props.children}
    </div>
  )
}

function PageFooter (props) {
  return (
    <div className="PageFooter">
      {props.children}
    </div>
  )
}

function PageFooterBody (props) {
  return (
    <div className="PageFooterBody">
      {props.children}
    </div>
  )
}

export default function SitePageFooter ({ hideLinks }) {
  return (
    <PageFooterContainer>
      {!hideLinks && <Link to="/contact">Contact</Link>}
      {!hideLinks && <Link to="/terms-and-conditions">Terms and conditions</Link>}
      {!hideLinks && <Link to="/work-with-us">Work with us</Link>}
      <PageFooter>
        <PageFooterBody>MyMark © Copyright 2021</PageFooterBody>
      </PageFooter>
    </PageFooterContainer>
  )
}