import { createInterfaceClass } from 'component-registry'
const Interface = createInterfaceClass('influence-app')

/**
 * Looks up the API client utility to connect to backend.
 * @class
 */
export const IApiClient = new Interface({
  name: 'IApiClient'
})

/**
 * Looks up the session manager utility.
 * @class 
 */
export const ISessionManager = new Interface({
  name: 'ISessionManager'
})

/**
 * Looks up the page manager utility.
 * @class
 */
export const IPageManager = new Interface({
  name: 'IPageManager',
  /**
   * Set the page meta data
   * @function IPageManager#setMetaData
   * @param {Object} metaData - meta data for page head
   *  @param {string} metaData.title
   *  @param {string} metaData.description
   *  @param {Object} metaData.image - sharing image
   *   @param {string} metaData.image.url - url to image
   *   @param {number} metaData.image.width - width of image
   *   @param {number} metaData.image.height - height of image
   *  @param {string} metaData.url - canonical url
   * 
   * @example
   * {
   *  title: 'Page title',
   *  description: 'Page description.',
   *  image {
   *    url: 'https://path.to/image',
   *    width: 290,
   *    height: 100
   *  },
   *  url: 'https://path.to/page'
   * }
   */
  setMetaData (metaData) {}
})

/**
 * Looks up the analytics utility to allow sending tracking information.
 * @class
 * @method pageView
 * @method screenView
 * @method interactiveEvent
 * @method noninteractiveEvent
 * @method session
 */
export const IAnalytics = new Interface({
  name: 'IAnalytics',
  /**
   * Track a web page view
   * @param {string} pagePath Path to page i.e. "/path/to/page"
   * @param {string} pageTitle Title of page "Start Page"
   */
  pageView (pagePath, pageTitle) {},
  /**
   * Track an app screen view
   * @method IAnalytics.screenView
   * @param {string} screenName Name of screen i.e. "Start Screen"
   */
  screenView (screenName) {},
  /**
   * Track an interactive event such as a button click.
   * @param {string} eventCategory Event category
   * @param {string} eventAction Event name
   * @param {string} eventLabel Human readable event name of event i.e. "Purchased Product"
   * @param {number} eventValue value used to aggregate calls by average or sum
   */
  interactiveEvent (eventCategory, eventAction, eventLabel, eventValue) {},
  /**
   * Track a non-interactive event such as a timer or network call.
   * @param {string} eventCategory Event category
   * @param {string} eventAction Event name
   * @param {string} eventLabel Human readable event name of event i.e. "Purchased Product"
   * @param {number} eventValue value used to aggregate calls by average or sum
   */
  noninteractiveEvent (eventCategory, eventAction, eventLabel, eventValue) {},
  /**
   * Common function to queue and send tracking events.
   * @param {string} hitType Type of hit [session|event|pageview|screenview]
   * @param {*} params The event params specific to chosen hitType
   */
  trackAction (hitType, params) {},
  /**
   * Track the start or end of a session
   * @function IAnalytics#session
   * @param {string} type The session type [start|end]
   */
  session (type) {}
})