if (process.env['NODE_ENV'] !== 'production') {
  if (typeof window !== 'undefined' && (!window.__env__ || !window.__env__['API_URI'])) {
    // TODO: This is literally dev settings and should really be configured in a single place
    /**
     * 
     * WARNING! This needs to be refactored so we don't track API-keys. Th keys neeed to be
     * regenerated.
     * 
     * */
    window.__env__ = {
      API_URI: 'http://localhost:3011/restish',
      API_UPLOAD_URI: 'http://localhost:3011/upload',
      INSTAGRAM_CLIENT_ID: '0c8d9db0e1cc4e5c8cd52561a76660cf',
      INSTAGRAM_CALLBACK_URI: 'http://localhost:3011/instagram_cb',
      INSTAGRAM_LOGIN_CALLBACK_URI: 'http://localhost:3000/admin',
      FACEBOOK_CLIENT_ID: '720532525352621',
      FACEBOOK_CALLBACK_URI: 'http://localhost:3011/facebook_cb',
      FACEBOOK_LOGIN_CALLBACK_URI: 'http://localhost:3000/admin',
      GOOGLE_API_KEY: 'AIzaSyBO1hh52ZwYjpFQZLWYn-oXbKMInzU4cpU',
      STRIPE_PUBLIC_API_KEY: 'pk_test_51HUIpKJpVHjgug9GAMs9rsf0P9nbxrIxVhFWZeExZNoaZdamNy0A2ZEEHUqhlD23XQmil0wkT6FpxRZrD7jLTYsw00wjr4nqsm',
      NODE_ENV: undefined,

      GA_APP_NAME: 'MyMarkWeb',
      GA_APP_ID: 'store.mymark.web',
      GA_APP_VERSION: '1.0.0',
    }
  }
}