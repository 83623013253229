import { Component } from 'inferno'
import classnames from 'classnames'
import Hls from 'hls.js'
import IconScrollDown from '../../../widgets/icon_scroll_down'
import './Hero.scss'

const VIDEO_PLAYBACK_ID = 'dDBCtVfMflQBhzDT9XW5uIruW02T6nSeKTJnO1J8vCa00'

export class Hero extends Component {

  state = {
    doAnimate: true
  }

  componentDidMount() {
    this._initHls()
    window.addEventListener('scroll', this.didScroll)
  }
  
  componentWillUnmount() {
    this.hls && this.hls.destroy()
    window.removeEventListener('scroll', this.didScroll)
  }

  render () {
    return (
      <div className={classnames("Hero", {animate: this.state.doAnimate})}>
        <div className="HeroContent">
          <video ref={(el) => this._videoEl = el} autoplay playsinline preload muted="true"
            src={`https://stream.mux.com/${VIDEO_PLAYBACK_ID}.m3u8`} />
        </div>
        <div className="bottomCenter">
          <IconScrollDown />
        </div>
      </div>
    )
  }

  _initHls() {
    if (this._videoEl) {
      const video = this._videoEl;
      let src = video.src

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        // so we do nothing
        // video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        this.hls = new Hls();
        this.hls.loadSource(src);
        this.hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }
  }

  didScroll = () => {
    if (this._debounce) return

    requestAnimationFrame(() => {
      if (window.scrollY > 50) {
        this.setState({
          doAnimate: false
        })
      }
      else if (!this.state.doAnimate) {
        this.setState({
          doAnimate: true
        })
      }
      this._debounce = null
    })
  }
}