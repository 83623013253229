import classnames from 'classnames'
import './ProgressIndicator.scss'

export const Progress = ({step, total}) => {
  let tmp = []
  for (var t = 0; t < total; t++) {
    tmp.push(<div className={classnames("step", {'mark': t < step})}></div>)
  }
  
  return (
    <div className="ProgressIndicator">
      {tmp}
    </div>
  )
}