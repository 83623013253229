import { createObjectPrototype, Utility } from 'component-registry';
import assert from 'assert';
import { Permissions } from 'influence-permissions/dist/permissions';
import { OrderWorkflow } from './workflows/OrderWorkflow';
import { IOrder } from '../interfaces/Order';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin', 'customerService', 'anonymous'],
  // TODO: Anon should not be alowed to create image
  read: ['owner', 'admin', 'customerService', 'anonymous:orderWorkflow.pending', 'anonymous:orderWorkflow.payed', 'anonymous:orderWorkflow.produced', 'anonymous:orderWorkflow.sent', 'anonymous:orderWorkflow.canceled'],
  update: ['owner', 'admin', 'customerService', 'anonymous:orderWorkflow.pending'],
  delete: ['owner', 'admin', 'customerService']
};
var Order = createObjectPrototype({
  implements: [IOrder],
  extends: [Permissions, OrderWorkflow],
  constructor: function constructor(params) {
    this._type = 'Order';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IOrderWorkflow.constructor.call(this, params, {
      defaultState: 'pending'
    });

    if (params.order && typeof params.order.orderDate === 'string') {
      params.order.orderDate = new Date(params.order.orderDate);
    }
  }
});
export default Order;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Order',
  getPrototype: function getPrototype() {
    return Order;
  },
  getObject: function getObject(data) {
    data = data || {};
    return new Order(data);
  }
});