import { interfaces } from 'inferno-formlib'
import { Utility } from 'component-registry'
import { en } from './en'
const { ITranslationUtil } = interfaces

const langLibrary = { en }

new Utility({
  implements: ITranslationUtil,
  message (label, lang) {
    // Use 'en' as fallback language
    const langDict = langLibrary[lang] || langLibrary['en']
    // Use label as fallback string
    return langDict[label] || label
  }
})

/**
 * Translate label to current language.
 * @param {*} label - i18n label 
 * @param {*} fallback - fallback text if no translation is found
 */
function i18n (label, fallback) {
  return new ITranslationUtil().message(label, 'en')
}

export {
  i18n
}