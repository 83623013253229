import { safeGet, safeJoin } from 'safe-utils'
import SnippetComponent from '../SnippetComponent'
import { i18n } from '../../../i18n'
import { Button } from 'inferno-bootstrap'
import { IAnalytics } from '../../../interfaces/app'

import { FormRows } from 'inferno-formlib'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends SnippetComponent {

  constructor () {
    super (...arguments)

    this.state = {
      formValue: {},
      validationErrors: undefined,
      submitted: false
    }
  }

  doGoNext = (e) => {
    e.preventDefault()
    const formData = this.props.order['productConfiguration'] || {}
    const errors = this.props.formSchema.validate(formData)
    this.setState({
      validationErrors: errors,
      submitted: true
    })

    if (!errors) {
      // new IAnalytics().interactiveEvent('action', 'to_order', 'To Order')
      this.props.onComplete()
    }
  }

  doGoPrev = (e) => {
    e.preventDefault()
    this.props.onBack()
  }

  didChange = (propName, value) => {
    const val = this.props.order['productConfiguration'] || {}
    // Force some transforms for consitent input
    if (['initials', 'contactInitials'].indexOf(propName) >= 0) {
      val[propName] = safeGet(() => value.toUpperCase())
    }
    else {
      val[propName] = value
    }

    let errors
    if (this.state.submitted) {
      errors = this.props.formSchema.validate(val)  
    }
    this.setState({
      validationErrors: errors,
    })
    this.props.onChange('productConfiguration', val)
  }

  render ({children, hasBack, TagComponent, formSchema, order}) {

    return (
        <div className="Configurator Step">
            <div className="ContentBody">
              <TagComponent {...order.productConfiguration} />
            </div>

            <div className="ContentBody">
              <form onAction={this.doGoNext}>
                <FormRows
                  schema={formSchema}
                  validationErrors={this.state.validationErrors}
                  value={order.productConfiguration}
                  onChange={this.didChange} />

                {children}

                <div className="actions">
                  {hasBack && <Button color="link" onClick={this.doGoPrev}>Back</Button>}
                  <Button color="primary" onClick={this.doGoNext}>Next</Button>
                </div>
              </form>
            </div>
        </div>
    )
  }
}

