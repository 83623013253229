import { Component } from 'inferno'
import { i18n } from '../i18n'
import { Button } from 'inferno-bootstrap'
import { Link } from 'inferno-router'
import { Hero, HeroBody } from '../widgets/Hero'
import { IPageManager } from '../interfaces/app'
import SitePageHeader from '../widgets/SitePageHeader'
import SitePageFooter from '../widgets/SitePageFooter'

import './public/Start/index.scss'
import placeholder_jpg from './public/Start/placeholder.jpg'
import logo_svg from '../img/logo/logo-colored.svg'

function Stripe (props)  {
  return (
    <p><span className="HeroBody-Stripe">{props.children}</span></p>
  )
}
function StripeHeader (props)  {
  return (
    <h2><span className="HeroBody-Stripe">{props.children}</span></h2>
  )
}

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends Component {

  
    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'MyMark - Error Page!',
        description: `Error Page!`,
        url: `${FRONTEND_BASE_URI}/error`
      })
    }

    render ({ match }) {
        const { errorCode } = match.params

        return (
            <div className="Fullpage">
              <SitePageHeader />
              <Hero imgSrc={placeholder_jpg}>
                <HeroBody className="Hero-Influencer">
                  <StripeHeader>{errorCode} Error!</StripeHeader>
                  <Stripe>The page can't be found, or</Stripe>
                  <Stripe>you don't have permission to view it.</Stripe>
                  <Stripe>Check spelling or log in.</Stripe>
                  <Button onClick={(e) => {e.prevetDefault(); this.context.router.history.push('/')}}><Stripe>MyMark</Stripe></Button>
                  {/*<Button tag={Link} to="register_2"><Stripe>Sign up now »</Stripe></Button>*/}
                </HeroBody>
              </Hero>
              <SitePageFooter />
            </div>
        )
    }
}

