import classnames from 'classnames'
import { Link } from 'inferno-router'
import { Logo } from '../../widgets/LogoMyMark'
import SitePageFooter from '../../widgets/SitePageFooter'

import IconStore from '../../widgets/icon_store'
import './PageChrome.scss'

export const PageChrome = ({className, hideLinks, children}) => {
  return (
    <div className={classnames('PageChrome', className)}>
      <div className="Header">
        <div className="leading"></div>
        <Link to="/"><Logo inverse /></Link>
        <div className="trailing"><Link to="/product"><IconStore /></Link></div>
      </div>
      <div className="Content">{children}</div>
      <SitePageFooter hideLinks={hideLinks} />
    </div>
  )
}