import { Component } from 'inferno'
import { Placeholder } from '../../widgets/Placeholder'

export default class Page extends Component {

  componentDidUpdate() {
    const { match } = this.props
    setTimeout(() => {
      this.context.router.history.push(`/order/${match.params.pathId}/receipt`)
    }, 3000)
  }

  render() {
    return <Placeholder height="100vh" bg="#ccc" fg="#fff">Stripe</Placeholder>
  }
}