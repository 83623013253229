import logo_svg from '../img/logo/logo.svg';
import logo_inverse_svg from '../img/logo/logo_inverse.svg';

export const Logo = ({ inverse = false }) => {
  if (inverse) {
    return <img className="MyMarkLogo inverse" src={logo_inverse_svg} />
  }
  else {
    return <img className="MyMarkLogo" src={logo_svg} />
  }
}