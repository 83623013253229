import airpods_pro_jpg from './AirpodsPro.jpg'
import bottle_jpg from './Bottle.jpg'
import charger_jpg from './Charger.jpg'
import iphone_jpg from './iPhone.jpg'
import keyring_jpg from './Keyring.jpg'
import wallet_jpg from './Wallet.jpg'
import sheets_jpg from './Sheets.jpg'
import './products_new.scss'

export function AirPodsPro({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={airpods_pro_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="airpodspro" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text font-family="Gilroy-Medium, Gilroy" fill="currentColor">
                      <tspan x="600" y="640" text-anchor="middle">{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Bottle({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={bottle_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="bottle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text fill="currentColor">
                      <tspan x="600" y="640" text-anchor="middle">{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Charger({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={charger_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="charger" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text fill="currentColor">
                      <tspan x="600" y="640" text-anchor="middle">{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Iphone({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={iphone_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="iphone" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text x="600" y="640" fill="currentColor" text-anchor="middle">
                      <tspan>{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Keyring({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={keyring_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="keyring" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text x="600" y="640" fill="currentColor" text-anchor="middle">
                      <tspan>{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Wallet({ text }) {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={wallet_jpg} className="ProductPreview-image" />
        <div className="text-container">
          <svg width="1200px" height="1200px" viewBox="0 0 1200 1200">
            <g id="wallet" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="text">
                  <text x="600" y="640" fill="currentColor" text-anchor="middle">
                      <tspan>{text}</tspan>
                  </text>
                </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export function Sheets() {
  return (
    <div className="ProductPreview">
      <div className="product-container">
        <img src={sheets_jpg} className="ProductPreview-image" />
      </div>
    </div>
  )
}