import Price from '../../../utils/Price'
import Carousel from 'inferno-carousel-pure'
import { Button } from 'inferno-bootstrap'
import {
  AirPodsPro,
  Bottle,
  Charger,
  Iphone,
  Keyring,
  Sheets,
  Wallet
} from '../../../widgets/products/products_new'

import 'inferno-carousel-pure/lib/index.scss'
import './ProductPromo.scss'

export const ProductPromo = ({children}, {router}) => {
  const text = 'AH'
  return (
    <div className="ProductPromo">
      <div className="carousel">
          <Carousel>
            <AirPodsPro text={text} />
            <Charger text={text} />
            <Wallet text={text} />
            <Iphone text={text} />
            <Bottle text={text} />
            <Keyring text={text} />
            <Sheets />
          </Carousel>
      </div>
      <div className="body">
        {children}

        <div className="cta">
          <div className="leading">
            <h3>{Price.toStr(Price.BASE_PRICE)}</h3>
            <p>Free shipping</p>
          </div>
          <Button color="primary" onClick={() => router.history.push('/product')}>Order Now</Button>
        </div>
      </div>
    </div>
  )
}