export function Placeholder ({ children, height, bg, fg }) {
  const outerStyle = {
    "min-height": height,
    "background-color": bg,
  }

  const innerStyle = {
    color: fg
  }
  return (
    <div className="placeholder" style={outerStyle}>
      <div className="placeholder-text" style={innerStyle}>{children}</div>
    </div>
  )
}

export function Arrow ({ fg }) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style={{ stroke: fg, fill: fg }} className="placeholder-cta-arrow" d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
}

export function CallToAction ({ children, height, bg, fg }) {
  const outerStyle = {
    "min-height": height,
    "background-color": bg,
    color: fg
  }
  return (
    <div className="placeholder" style={outerStyle}>
      <div className="placeholder-cta">{children}</div>
    </div>
  )
}