export default function () {
  return (
    <svg width="100px" height="100px" viewBox="0 0 100 100">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="icon_shop">
              <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
              <path d="M50,13 C60.1901536,13 68.5066645,21.8664412 68.9788434,33.0001409 L77,33 L77,87 L23,87 L23,33 L31.0211566,33.0001409 C31.4933355,21.8664412 39.8098464,13 50,13 Z M73,37 L27,37 L27,83 L73,83 L73,37 Z M50,17 C41.8677281,17 35.2469677,24.1187049 35.0067507,33.0001796 L64.9932493,33.0001796 C64.7530323,24.1187049 58.1322719,17 50,17 Z" id="Combined-Shape" fill="currentColor"></path>
          </g>
      </g>
  </svg>
  )
}