export default function Star() {
  return (
    <svg width="123px" height="131px" viewBox="0 0 123 131" version="1.1">
        <defs>
            <polygon id="path-1" points="59 121.107343 47.489671 124.518294 38.080613 116.673764 26.2213563 115.131849 20.3460131 104.047998 9.94329287 97.7879597 8.49613223 85.152204 1.13366846 75.1270767 4.33500744 62.8630952 1.13366846 50.5991137 8.49613223 40.5739865 9.94329287 27.9382308 20.3460131 21.6781925 26.2213563 10.5943418 38.080613 9.0524266 47.489671 1.20789675 59 4.61884721 70.510329 1.20789675 79.919387 9.0524266 91.7786437 10.5943418 97.6539869 21.6781925 108.056707 27.9382308 109.503868 40.5739865 116.866332 50.5991137 113.664993 62.8630952 116.866332 75.1270767 109.503868 85.152204 108.056707 97.7879597 97.6539869 104.047998 91.7786437 115.131849 79.919387 116.673764 70.510329 124.518294"></polygon>
            <filter x="-3.3%" y="-3.0%" width="109.9%" height="109.2%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.220143138 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Configure-Copy-4" transform="translate(-235.000000, -55.000000)">
                <g id="Star" transform="translate(236.000000, 56.000000)">
                    <use fill="black" fill-opacity="1" filter="url(#filter-2)" href="#path-1"></use>
                    <use fill="currentColor" fill-rule="evenodd" href="#path-1"></use>
                </g>
            </g>
        </g>
    </svg>
  )
}