import { Component } from 'inferno'
import { i18n } from '../../i18n'
import { Container, Col, Row, Button } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import SitePageHeader from '../../widgets/SitePageHeader'
import SitePageFooter from '../../widgets/SitePageFooter'
import { IApiClient, ISessionManager, IPageManager } from '../../interfaces/app'
import './PageTemplate.scss'
import '../onboarding/RegisterStep.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends Component {
  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Privacy Policy',
      description: `MyMark privace policy document.`,
      url: FRONTEND_BASE_URI + location.pathname
    })
  }

  render() {
    return (
      <Animated key="me" prefix="PageNav">
        <div className="ContentPage">
          <SitePageHeader />
          <Container fluid>
              <Row className="col">
                <h2>Privacy Policy</h2>
                <p>To sign up, all you need is a Facebook account.</p>
                <p>We take privacy seriously and will not share your data with a third party unless you give us permission.</p>
                <p>For a complete removal of your personal data, please contact our support <a href="malto:support@memly.io">support@memly.io</a> and we will help you out with the practical issues.</p>
              </Row>
              <Row className="col">
                <h2>Cookies</h2>
                <p>We use cookies to gather anonymous visitor stats. That is it for now.</p>
              </Row>
          </Container>
          <SitePageFooter />
        </div>
      </Animated>
    )
  }
}

