import { safeGet, safeJoin } from 'safe-utils'
import SnippetComponent from '../SnippetComponent'
import querystring from 'querystring'
import { i18n } from '../../../i18n'
import { Button } from 'inferno-bootstrap'
import Carousel from 'inferno-carousel-pure'
import { Link } from 'inferno-router'
import { IApiClient, IPageManager, IAnalytics } from '../../../interfaces/app'
import { PageChrome } from '../PageChrome'

import {
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'
import { FormRows } from 'inferno-formlib'

import 'inferno-carousel-pure/lib/index.scss'
import './index.scss'
import Star from '../../../widgets/products/Star'

import { IDeserialize, IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IPromoCode } from 'mytag-app-entities/lib/interfaces/PromoCode'
import { IOrder, productConfigurationSchema } from 'mytag-app-entities/lib/interfaces/Order'

import ConfigurationStep from './step'
import { Progress } from './ProgressIndicator'

import {
  AirPodsWithInitials,
  AirPodsWithFullName,
  AirPodsWithContact,
} from '../../../widgets/products/airpods_w_tag'

import {
  IphoneBelongsTo,
  IphoneWithInstagram,
} from '../../../widgets/products/iphone_w_tag'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

// This is a subset of the entire form so we create a specific validation schema
// containing only the props entered in this step
const formSchemaStep1 = new Schema('Configurator Validation Form 1', {
  initials: productConfigurationSchema._fields['initials'],
})
const formSchemaStep2 = new Schema('Configurator Validation Form 2', {
  firstName: productConfigurationSchema._fields['firstName'],
  lastName: productConfigurationSchema._fields['lastName'],
})
const formSchemaStep3 = new Schema('Configurator Validation Form 3', {
  contactInitials: productConfigurationSchema._fields['contactInitials'],
  contactPhone: productConfigurationSchema._fields['contactPhone'],
})
const formSchemaStep4 = new Schema('Configurator Validation Form 4', {
  belongsToFirstName: productConfigurationSchema._fields['belongsToFirstName'],
})
const formSchemaStep5 = new Schema('Configurator Validation Form 5', {
  instagram: productConfigurationSchema._fields['instagram'],
})

const formSchemaAllSteps = new Schema('Configurator Validation Form', {
  initials: productConfigurationSchema._fields['initials'],
  firstName: productConfigurationSchema._fields['firstName'],
  lastName: productConfigurationSchema._fields['lastName'],
  contactInitials: productConfigurationSchema._fields['contactInitials'],
  contactPhone: productConfigurationSchema._fields['contactPhone'],
  belongsToFirstName: productConfigurationSchema._fields['belongsToFirstName'],
  instagram: productConfigurationSchema._fields['instagram'],
})

export default class Page extends SnippetComponent {

  constructor () {
    super (...arguments)

    const order = new IObjectPrototypeFactory('Order').getObject()

    // Add PromoCode if we have one
    const [productInfo, promo] = this.props.fetchData || []
    if (IPromoCode.providedBy(promo)) {
      order.discountCode = promo.discountCode
    }

    this.state = {
      formValue: order,
      validationErrors: undefined,
      submitted: false,
    }
  }

  
  static async fetchData ({registry, match, location}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Product Configurator',
      description: `MyMark product configurator.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    if (match.params.discountCode) {
      const { data } = await new IApiClient({ registry }).query([
        { URI: `/content/Page/productInfo` },
        { URI: `/order/PromoCode/${match.params.discountCode}` },
      ])
      return data
    }
    else {
      const { data } = await new IApiClient({ registry }).query([
        { URI: `/content/Page/productInfo` },
      ])
      return data
    }
  }

  doSubmit = () => {
    const formData = this.state.formValue
    const errors = formSchemaAllSteps.validate(formData.productConfiguration)
    this.setState({
      validationErrors: errors,
      submitted: true
    })

    // TODO: Show errors?

    if (!errors) {
      const order = this.state.formValue

      // Create an order
      new IApiClient().create({
        URI: '/order/Order',
        data: Object.assign({}, order),
        invalidate: '/order/Order'
      }).then(({data}) => {
        new IAnalytics().interactiveEvent('action', 'to_order', 'To Order')
        this.context.router.history.push(`/order/${data._pathId}/checkout`)
      })
    }
  }

  _goToStep = (step) => {
    const { history, route } = this.context.router
    if (step > 1) {
      history.push(route.location.pathname + '?' + querystring.encode({step}))
    }
    else {
      history.push(route.location.pathname)
    }
  }

  doGoNext = () => {
    // Populate similar props for product config
    let { formValue } = this.state
    let { productConfiguration } = formValue || {}
    if (productConfiguration.contactInitials === undefined) {
      productConfiguration.contactInitials = productConfiguration.initials
    }
    if (productConfiguration.belongsToFirstName === undefined) {
      productConfiguration.belongsToFirstName = productConfiguration.firstName
    }
    formValue.productConfiguration = productConfiguration

    this.setState({
      formValue
    })
    
    const formStep = this._getStepIndex()
    this._goToStep(formStep + 1)
    window.scrollTo(0,0)
  }

  doGoPrev = () => {
    const formStep = this._getStepIndex()
    this._goToStep(formStep - 1)
    window.scrollTo(0,0)
  }

  didChange = (propName, value) => {
    const val = this.state.formValue
    val[propName] = value
    this.setState({
      formValue: val,
    })
  }

  renderDiscountHeader() {
    const [productInfo, promo] = this.props.fetchData || []
    let hasDiscount = IPromoCode.providedBy(promo) && promo.discountCode

    if (hasDiscount && promo.influencerInstagramAccount) {
      return (
        <div className="ContentBody Discount">
          <div className="PromoCode">
            <div className="PromoCode-Text">
              <p>Offer to followers of</p>
              <p><a target="_blank" href={'https://www.instagram.com/' + promo.influencerInstagramAccount.replace('@', '')}>{promo.influencerInstagramAccount}</a></p>
              <p className="small">Discount applied</p>
              <p className="small">during checkout!</p>
            </div>
            <div className="PromoCode-Badge">
              <Star />
              <div className="text">-{promo.discountAmount}%</div>
            </div>
          </div>
        </div>
      )
    }
    else return null
  }

  _getStepIndex = () => {
    const { step } = querystring.parse(this.props.location.search.replace(/^\?/, ''))
    return parseInt(step) || 1
  }

  render () {
    const [productInfo, promo] = this.props.fetchData || []
    const mobiledoc = safeGet(() => productInfo.body)
    const formStep = this._getStepIndex()

    const stepProps = {
      order: this.state.formValue,
      onChange: this.didChange,
      onComplete: this.doGoNext,
      onBack: this.doGoPrev,
    }
    
    return (
        <PageChrome hideLinks className="Fullpage Configurator">
          <Progress step={formStep} total={6} />
          <div className="ContentContainer">
            {this.renderDiscountHeader()}

            <div className="ContentBody Slider">
              <Carousel
                swiping={false}
                dragging={false}
                slideIndex={formStep - 1}
                heightMode="current"
                withoutControls>
                <ConfigurationStep {...stepProps} TagComponent={AirPodsWithInitials} formSchema={formSchemaStep1}>
                  <ul>
                    <li>Type your initials or any two letter combination</li>
                    <li>You will get two sizes of this tag in different colours</li>
                    <li>This is your discreet tag that fits on almost anything</li>
                  </ul>
                </ConfigurationStep>
                <ConfigurationStep {...stepProps} hasBack TagComponent={AirPodsWithFullName} formSchema={formSchemaStep2}>
                  <ul>
                    <li>Type your name or two words for this tag</li>
                    <li>You will get two sizes in black</li>
                    <li>The text on these tags is small but readable</li>
                  </ul>
                </ConfigurationStep>
                <ConfigurationStep {...stepProps} hasBack TagComponent={AirPodsWithContact} formSchema={formSchemaStep3}>
                  <ul>
                    <li>Type your initials and a phone number</li>
                    <li>You will get one size of this tag in black</li>
                    <li>This is your discreet tag that fits on almost anything</li>
                  </ul>
                </ConfigurationStep>
                <ConfigurationStep {...stepProps} hasBack TagComponent={IphoneBelongsTo} formSchema={formSchemaStep4}>
                  <ul>
                    <li>Type your first name</li>
                    <li>You will get one size of this tag in different colours</li>
                    <li>This tag speaks for itself</li>
                  </ul>
                </ConfigurationStep>
                <ConfigurationStep {...stepProps} onComplete={this.doSubmit} hasBack TagComponent={IphoneWithInstagram} formSchema={formSchemaStep5}>
                  <ul>
                    <li>Type your instagram handle</li>
                    <li>You will get a small number of tags and a surprise</li>
                    <li>This is your real life watermark for your instafeed</li>
                  </ul>
                </ConfigurationStep>
              </Carousel>
            </div>

            <div className="ContentBody">
              <div className="Info">
                {mobiledoc && this.renderer.render(mobiledoc)}
              </div>
            </div>
          </div>
        </PageChrome>
    )
  }
}

