import { Adapter } from 'component-registry'

import { Link } from 'inferno-router'
import {
  Row,
  Col,
  Nav,
  NavLink
} from 'inferno-bootstrap'
import { safeJoin } from 'safe-utils'
import { i18n } from '../../i18n'

import { IApiClient } from '../../interfaces/app'
import { IListItem, INotificationManager } from '../../interfaces/presentation'
import { IPromoCode } from 'mytag-app-entities/lib/interfaces/PromoCode'
import { IPromoCodeWorkflow } from 'mytag-app-entities/lib/interfaces/workflows'
import '../common/ListItem.scss'


const workflowStates = IPromoCodeWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.promoCodeWorkflow = newState
  new IApiClient().update({
    URI: `/order/PromoCode/${obj._id}`,
    data: obj,
    invalidate: `/order/PromoCode`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowActions ({ obj }, context) {
  
  return (
    <Nav className="actions" pills horizontal="end">
      {Object.keys(workflowStates).map((key) => {
        // Don't return the current state since we don't want to transition to ourself
        const isActive = (obj._workflows && obj._workflows.promoCodeWorkflow) === key
        if (isActive) return null

        return (
          <NavLink href="#" active={isActive} onClick={(e) => doChangeWorkflowState(obj, key)}>
            {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
          </NavLink>
        )
      })}
    </Nav>
  )
}

function ListItem (props, context) {
  const obj = props.context
  return (
    <Row className="list-item">
      <Col className="list-item-body">
        <Link to={`/admin/PromoCode/${obj._id}`}>{`${obj._pathId} ${safeJoin([obj.influencerName, obj.influencerInstagramAccount],' ')}`}</Link>
      </Col>
      <Col className="list-item-workflow">
        <h2 className="status">
          {obj._workflows && obj._workflows.promoCodeWorkflow}
        </h2>
        <WorkflowActions obj={obj} />
      </Col>
    </Row>
  )
}

new Adapter({
  implements: IListItem,
  adapts: IPromoCode,
  Component: ListItem
})