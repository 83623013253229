import { Adapter } from 'component-registry'

import { Link } from 'inferno-router'
import {
  Row,
  Col,
  Nav,
  NavLink
} from 'inferno-bootstrap'
import { safeJoin } from 'safe-utils'
import { i18n } from '../../i18n'

import { IApiClient } from '../../interfaces/app'
import { IListItem, INotificationManager } from '../../interfaces/presentation'
import { IOrder } from 'mytag-app-entities/lib/interfaces/Order'
import { IOrderWorkflow } from 'mytag-app-entities/lib/interfaces/workflows'
import '../common/ListItem.scss'
import './ListItem.scss'


const workflowStates = IOrderWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.orderWorkflow = newState
  new IApiClient().update({
    URI: `/order/Order/${obj._id}`,
    data: obj,
    invalidate: `/order/Order`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowActions ({ obj }, context) {
  
  return (
    <Nav className="actions" pills horizontal="end">
      {Object.keys(workflowStates).map((key) => {
        // Don't return the current state since we don't want to transition to ourself
        const isActive = (obj._workflows && obj._workflows.orderWorkflow) === key
        if (isActive) return null

        return (
          <NavLink href="#" active={isActive} onClick={(e) => doChangeWorkflowState(obj, key)}>
            {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
          </NavLink>
        )
      })}
    </Nav>
  )
}

function ListItem (props, context) {
  const obj = props.context
  return (
    <Row className="list-item">
      <Col className="list-item-body">
        <Link to={`/admin/Order/${obj._id}`}>{`${obj._pathId} ${safeJoin([obj.firstName, obj.lastName],' ')} ${obj.country || ''}`}</Link>
        <h3>{safeJoin([obj.postalCode, obj.postalCity, obj.address_1], ', ')}</h3>
      </Col>
      <Col className="list-item-workflow">
        <h2 className="status">
          {obj._workflows && obj._workflows.orderWorkflow}
        </h2>
        <WorkflowActions obj={obj} />
      </Col>
    </Row>
  )
}

new Adapter({
  implements: IListItem,
  adapts: IOrder,
  Component: ListItem
})