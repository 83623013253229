import { Utility } from 'component-registry'
import { IApiClient, IAnalytics } from '../interfaces/app'

/**
 * Set up env vars
 */
let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
const { GA_APP_NAME, GA_APP_ID, GA_APP_VERSION } = _env_

/**
 * Convert a tracking object to GA style get params.
 * @param {Object} obj 
 */
function convertToGetParams (obj) {
  var outp = Object.keys(obj).map(function (key) {
    return key + '=' + encodeURIComponent(obj[key])
  })
  return outp.join('&')
}

var uaRequestQueue = []
function trackAction (hitType, params) {
  const getParams = {
    v: 1,
    ds: 'web', // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#ds
    an: GA_APP_NAME, // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#an
    aid: GA_APP_ID, // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#aid
    av: GA_APP_VERSION, // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#av
    // Target account – this is set by server: tid: tid,
    // Session ID – this is set by server: cid: cid, // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#cid
    t: hitType, // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#t
    ua: window.navigator.userAgent // Add user agent https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#ua
  }
  console.log("# TRACK: " + convertToGetParams(getParams))

  uaRequestQueue.push({
      createdAt: Date.now(),
      body: Object.assign(getParams, params)
  })

  if (hitType === 'session' || uaRequestQueue.length == 20) {
    let url
    if (uaRequestQueue.length === 1) {
        url = 'https://www.google-analytics.com/collect'
    } else {
        url = 'https://www.google-analytics.com/batch'
    }

    // Pop events from queue
    var trackingEvents = uaRequestQueue.splice(0, uaRequestQueue.length)
    
    new IApiClient().create({
      URI: '/analytics/googleAnalytics',
      data: {
        now: new Date(),
        trackingEvents
      }
    })
    
    // DEBUG
    console.log("### SENDING TRACKING EVENTS ##")
    // console.log(JSON.stringify(payload))
    // DEBUG
  } else {
      // Do nothing, we do batching
      return
  }

}

if (typeof window !== 'undefined') {
  // Make sure we send the queue of events when window is closed
  window.addEventListener('beforeunload', (e) => {
    var params = {
      sc: 'end'
    }
    trackAction('session', params)
  });
}

var interactiveEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
  var params = {
    ec: eventCategory,
    ea: eventAction,
    el: eventLabel,

  }
  if (eventValue) {
    params['ev'] = eventValue
  }
  trackAction('event', params)
}

var noninteractiveEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
  var params = {
    ec: eventCategory,
    ea: eventAction,
    el: eventLabel,
    ni: 1
  }
  if (eventValue) {
    params['ev'] = eventValue
  }
  trackAction('event', params)
}

function pageView (pagePath, pageTitle) {
  var params = {
    dh: GA_APP_ID,
    dp: pagePath,
    dt: pageTitle
  }
  trackAction('pageview', params)
}

var screenView = function (screenName) {
  var params = {
    cd: screenName // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#cd
  }
  trackAction('screenview', params)
}

var session = function (type) {
  var params = {
    sc: type
  }
  trackAction('session', params)
}

/** @class */
const AnalyticsUtil = new Utility({
  /** @lends IAnalytics */
  implements: IAnalytics,
  interactiveEvent,
  noninteractiveEvent,
  pageView,
  screenView,
  session
})
