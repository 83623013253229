import { Component, Fragment } from 'inferno'
import { IListItem, INotificationManager } from '../../interfaces/presentation'
import { IApiClient, ISessionManager, IPageManager } from '../../interfaces/app'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'
import querystring from 'querystring'
import { saveAs } from 'file-saver';

import './List.scss'
import './Dashboard.scss'
import { Link } from 'inferno-router'
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'inferno-bootstrap'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import { WorkflowFilterField } from 'influence-app-entities/lib/customFields/WorkflowFilterField'
import '../../formlib/WorkflowFilterField'
import { IInvitationWorkflow, IUserWorkflow, IPublishWorkflow } from 'influence-app-entities/lib/interfaces/workflows'
import { IOrderWorkflow, IPromoCodeWorkflow } from 'mytag-app-entities/lib/interfaces/workflows'
import Page from './ListTemplate'
import { InfiniteScrollContainer } from '../../widgets/InfiniteScrollContainer'
import { FormRows } from 'inferno-formlib'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export class ListPage extends Component {

  static limit = 30

  constructor (props) {
    super(...arguments)

    this.state = {
      search: undefined,
      invitationWorkflow: undefined,
      userWorkflow: undefined,
      posts: props.fetchData || [],
      queryIsLoading: false,
      hasMore: true
    }
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Admin',
      description: `MyMark admin interface.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    const URI = `/admin/${safeGet(() => match.params.type, 'Invitation')}`
    const { search, invitationWorkflow, orderWorkflow, promoCodeWorkflow, userWorkflow } = location.search || {}

    let stateFilter
    switch (match.params.type) {
      case 'Order':
        stateFilter = (orderWorkflow && `orderWorkflow.${orderWorkflow}`) || '!orderWorkflow.trash'
        break
      case 'PromoCode':
        stateFilter = (promoCodeWorkflow && `promoCodeWorkflow.${promoCodeWorkflow}`) || '!promoCodeWorkflow.trash'
        break
      case 'Invitation':
        stateFilter = (invitationWorkflow && `invitationWorkflow.${invitationWorkflow}`) || '!invitationWorkflow.trash'
        break
      case 'User':
        stateFilter = (userWorkflow && `userWorkflow.${userWorkflow}`)
        break
    }
    
    const query = { 
      search: (search && search.length >= 3 ? { title: search } : undefined), 
      stateFilter
    }

    for (let k in query) {
      if (!query[k]) delete query[k]
    }

    try {
      const { data } = await new IApiClient({ registry }).query({
        URI,
        query: { 
          query,
          page,
          limit: ListPage.limit
        }
      })
      
      return data.sort((a, b) => b._createdAt - a._createdAt)
    }
    catch (e) {
      return []
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, invitationWorkflow, userWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, invitationWorkflow, userWorkflow }
    const posts = nextProps.fetchData || []
    this.setState({ posts, ...query })
  }

  loadMore = async () => {
    // load some more
    this._lastLoadedPage = parseInt(this.state.posts.length / 30)
    if (this._lastLoadedPage > 0) {
      this.setState({ queryIsLoading: true })

      this._lastLoadedPage++

      const { router } = this.context
      const { search, invitationWorkflow, userWorkflow } = this.state
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = { search, invitationWorkflow, userWorkflow }

      // TODO: Do we really need to pass apiClient?
      const data = await ListPage.fetchData({apiClient: new IApiClient(), match, location, router: this.context.router, page: this._lastLoadedPage})
      this.setState({
        posts: this.state.posts.concat(data)
      })

      // Delay so we don't get insane amounts of reloading because of
      // delay with reflow (this is actually a bit flaky because images
      // might be loaded too slow)
      setTimeout(() => this.setState({
        queryIsLoading: false,
        hasMore: Array.isArray(data) && data.length >= ListPage.limit
      }), 100) 
    }
  }

  didUpdate = async (propName, value) => {
    const { search, invitationWorkflow, userWorkflow } = this.state
    const state = { search, invitationWorkflow, userWorkflow }
    state[propName] = value

    this.setState(state)

    if (this._typeFilter) {
      clearTimeout(this._typeFilter)
    }

    this._typeFilter = setTimeout(async () => {
      const tmp = {}
      for (let k in state) {
        if (state[k]) tmp[k] = state[k]
      }
      const query = querystring.encode(tmp)

      const { router } = this.context

      // Update URL
      window.history.replaceState(undefined, 'Filter', router.route.location.pathname + (query ? '?' + query : ''))
      // router.history.push(router.route.location.pathname + (query ? '?' + query : ''))
      this._typeFilter = undefined
      
      // Update list
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = state

      this.setState({ queryIsLoading: true })
      
      const posts = await ListPage.fetchData ({match, location, router})
      
      this.setState({
        posts,
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= ListPage.limit
      })
    }, 200)

  }

  renderToolbarContent () {
    const { type } = this.props.match.params
    const listPageType = type || 'Invitation'

    switch (type) {
      case 'Invitation':
        return (
          <Link to={`/admin/${listPageType}/create`} color="link" className="action">{i18n('ListAction-createInvitation', '+ Skapa en ny inbjudan')}</Link>
        )
      case 'User':
        return (
          <Link to={`/admin/${listPageType}/create`} color="link" className="action">{i18n('ListAction-createUser', '+ Skapa en ny användare')}</Link>
        )
      case 'Order':
        return (<Fragment>
          <Link to={`/admin/${listPageType}/create`} color="link" className="action">{i18n('ListAction-createOrder', '+ Skapa en ny order')}</Link>
          <Button onClick={this.doShowExport}>Export for production</Button>
        </Fragment>)
      case 'PromoCode':
        return (
          <Link to={`/admin/${listPageType}/create`} color="link" className="action">{i18n('ListAction-createPromoCode', '+ Skapa en ny kod')}</Link>
        )
    }
  }

  renderFilterForm () {
    const filterValue = this.state

    let { type } = this.props.match.params || {}
    type = type || 'Invitation'

    const filterSchema = new Schema('Filter Schema', {
      search: new TextField({
        label: 'Filter list:',
        placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
      })
    })

    switch (type) {
      case 'Invitation':
        filterSchema._fields['invitationWorkflow'] = new WorkflowFilterField({
          workflowInterface: IInvitationWorkflow
        })
        break
      case 'User':
        filterSchema._fields['userWorkflow'] = new WorkflowFilterField({
          workflowInterface: IUserWorkflow
        })
        break
      case 'Page':
        filterSchema._fields['publishWorkflow'] = new WorkflowFilterField({
          workflowInterface: IPublishWorkflow
        })
        break
      case 'Order':
        filterSchema._fields['orderWorkflow'] = new WorkflowFilterField({
          workflowInterface: IOrderWorkflow
        })
        break
      case 'PromoCode':
        filterSchema._fields['promoCodeWorkflow'] = new WorkflowFilterField({
          workflowInterface: IPromoCodeWorkflow
        })
        break

    }

    return (
      <Form key={type} onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  render () {
    // Pass type to <Page> as either the param type, or MediaCard if on Media endpoint, or default to Page
    // This was implemented because we are mapping MediaCard to .../Media
    let { type } = this.props.match.params || {}
    type = type || 'Invitation'

    return (
      <Page
        match={this.props.match}
        type={type}
        toolbar={this.renderToolbarContent()}
        filter={this.renderFilterForm()}>
          <InfiniteScrollContainer
            className="List-Container"
            hasMore={this.state.hasMore}
            queryIsLoading={this.state.queryIsLoading}
            inBackground={false}
            loadMorePadding={100}
            onLoadMore={this.loadMore}>
          {this.state.posts.map((post) => {
            const ListItem = new IListItem(post).Component
            return <ListItem key={post._id} context={post} />
          })}
          </InfiniteScrollContainer>
          {this.renderExportModal()}
      </Page>
    )
  }

  renderExportModal () {
    return (
      <Modal isOpen={this.state.showExportModal} toggle={() => this.setState({ showExportModal: false })}>
        <ModalHeader>Export For Production</ModalHeader>
        <ModalBody>
          <p>Do you want to export all orders with status "Payed"?</p>
        </ModalBody>
        <ModalFooter className="ActionBarContent">            
            <Button color="link"
              onClick={(e) => {e.preventDefault(); this.setState({ showExportModal: false })}}>Close</Button>
            
            <Button color="primary"
              onClick={(e) => {this.doExport(e)}}>Export</Button>
        </ModalFooter>
      </Modal>
    )
  }

  doShowExport = (e) => {
    e && e.preventDefault()

    this.setState({
      showExportModal: true
    })
  }

  doExport = async (e) => {
    e && e.preventDefault()

    const URI = `/admin/Order`
    const query = { 
      stateFilter: 'orderWorkflow.payed'
    }

    // TODO: Handle errors
    const { data } = await new IApiClient().query({
      URI,
      query: { 
        query,
      }
    })
    
    const outpHeaders = [
      'initials',
      'firstName',
      'lastName',
      'contactInitials',
      'contactPhone',
      'belongsToFirstName',
      'instagram',
    ]
    const outpData = data.map(order => {
      // Create csv with escaped quotes
      return safeGet(() => outpHeaders.map(key => `"${order.productConfiguration[key].replace('"', '""')}"`).join(','))
    })
    let exportRows = [outpHeaders.join(','), ...outpData]

    const todayStr = (new Date()).toISOString().substring(0, 10)

    var blob = new Blob([exportRows.join('\n')], {type: "text/plain;charset=utf-8"})
    saveAs(blob, `export-mymark-${todayStr}.csv`)

    const toUpdate = data.map(order => {
      order._workflows.orderWorkflow = 'produced'
      return {
        URI: `/order/Order/${order._id}`,
        data: order,
        invalidate: `/order/Order`
      }
    })
    await new IApiClient().update(toUpdate)

    new INotificationManager().showSuccessMessage()
  }
}
