import { Component } from 'inferno'
import { IApiClient, IPageManager } from '../../interfaces/app'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'
import querystring from 'querystring'
import { Link } from 'inferno-router'
import {
  Row,
  Col,
  Nav,
  NavLink
} from 'inferno-bootstrap'

import { IDeserialize, IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IListItem, INotificationManager } from '../../interfaces/presentation'

import './List.scss'
import './List-Page.scss'
import './Dashboard.scss'
import Page from './ListTemplate'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

const pagePaths = [
  'terms-and-conditions',
  'contact',
  'work-with-us',
]

const snippetPaths = [
  'receiptFinePrint',
  'productInfo',
]

export class ListPage extends Component {

  static limit = 30

  constructor (props) {
    super(...arguments)

    this.state = {
      pages: props.fetchData || [],
      queryIsLoading: false
    }
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Admin',
      description: `MyMark admin interface.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    const pageSlots = [...pagePaths, ...snippetPaths]
    try {
      const { data } = await new IApiClient({ registry }).query(
        pageSlots.map((id) => ({
          URI: `/content/Page/${id}`
        })
      ))
      
      return data
    }
    catch (e) {
      return []
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, invitationWorkflow, userWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, invitationWorkflow, userWorkflow }
    const pages = nextProps.fetchData || []
    this.setState({ pages, ...query })
  }

  doCreate = async (pathId) => {
      const newPage = new IObjectPrototypeFactory('Page').getObject({ _pathId: pathId })

      // Create an order
      const { data } = await new IApiClient().create({
        URI: '/content/Page',
        data: newPage,
        invalidate: '/content/Page'
      })

      this.context.router.history.push(`/admin/Page/${data._pathId}`)
  }

  _getPage = (pathId) => {
    return this.state.pages.reduce((curr, next) => curr || (next && next._pathId === pathId ? next : undefined), undefined)
  }

  render () {
    // Pass type to <Page> as either the param type, or MediaCard if on Media endpoint, or default to Page
    // This was implemented because we are mapping MediaCard to .../Media

    return (
      <Page
        match={this.props.match}
        type="Page">
        <div className="List-Container">
          <h2>Pages</h2>
          {pagePaths.map((path) => <PageSlot obj={this._getPage(path)} pathId={path} onCreate={this.doCreate} />)}
          <h2>Snippets</h2>
          {snippetPaths.map((path) => <PageSlot obj={this._getPage(path)} pathId={path} onCreate={this.doCreate} />)}
        </div>
      </Page>
    )
  }
}

function PageSlot({obj, pathId, onCreate}) {
  if (obj === undefined) {
    return (
      <Row className="list-item">
        <Col className="list-item-body">
          <a href={`/content/Page/create?pathId=${pathId}`} onClick={(e) => {e.preventDefault(); onCreate(pathId)}}>{pathId}</a>
          <h3>Click the link to create this page</h3>
        </Col>
        <Col className="list-item-workflow">
          <h2 className="status">
            TO BE CREATED
          </h2>
        </Col>
      </Row>
    )
  }
  else {
    const ListItem = new IListItem(obj).Component
    return <ListItem key={obj._id} context={obj} />
  }
}
