export default function () {
  return (
    <svg className="animated bounce" width="50px" height="56px" viewBox="0 0 50 56">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icon_scroll_down">
                <rect id="Rectangle" x="0" y="0" width="50" height="56"></rect>
                <polygon id="Triangle" fill="currentColor" transform="translate(25.000000, 39.000000) scale(1, -1) translate(-25.000000, -39.000000) " points="25 32 40 46 10 46"></polygon>
                <rect id="Rectangle" fill="currentColor" x="10" y="21" width="30" height="6"></rect>
                <rect id="Rectangle" fill="currentColor" x="10" y="10" width="30" height="6"></rect>
            </g>
        </g>
    </svg>
  )
}