import { Adapter, Utility } from 'component-registry'
import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'

import './PrintTags.scss'

export default class Page extends Component {
  componentDidMount() {
    window.print()
    this.props.onDone && this.props.onDone()
  }

  renderTag = () => {
    return (
      <div className="Tag TagOne">
        <h2>Tag 1</h2>
      </div>
    )
  }

  render ({ order }) {
    return (
      <div className="PrintableTags">
        <h1>Print Page</h1>
        {[1,2,3,4,5,6].map(this.renderTag)}
      </div>
    )
  }
}