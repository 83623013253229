import { Adapter, Utility } from 'component-registry'
import { Component } from 'inferno'
import { safeGet, safeJoin } from 'safe-utils'
import { i18n } from '../../i18n'

import './PrintAddress.scss'

export default class Page extends Component {
  componentDidMount() {
    window.print()
    this.props.onDone && this.props.onDone()
  }

  render ({ order }) {
    return (
      <div className="PrintableAddress">
        <div className="Address">
          <h1>{safeJoin([order.firstName, order.lastName], ' ')}</h1>
          <h2>{order.address_1}</h2>
          {order.address_2 && <h2>{order.address_2}</h2>}
          <h2>{safeJoin([order.postalCode, order.postalCity], ' ')}</h2>
          <h2>{order.country}</h2>
        </div>
      </div>
    )
  }
}