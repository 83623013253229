import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { i18n } from 'isomorphic-schema';
/**
 * IMPORTANT
 * 
 * When adding a workflow you also need to add the corresponding property to the
 * Elasticsearch index and migrate the database!!!
 * 
 * Currently: 		
   "workflows": {
			"properties": {
				"OrderWorkflow": { "type": "keyword" },
				"userWorkflow": { "type": "keyword" },
				"invitationWorkflow": { "type": "keyword" }
			}
		}
 */

export var IOrderWorkflow = new Interface({
  // Interface for OrderWorkflow
  name: 'IOrderWorkflow',
  schema: {
    statePropName: 'orderWorkflow',
    workflowStates: {
      pending: {
        title: i18n('IOrderWorkflow-option-pending', 'Pending')
      },
      payed: {
        title: i18n('IOrderWorkflow-option-payed', 'Payed')
      },
      produced: {
        title: i18n('IOrderWorkflow-option-produced', 'Produced')
      },
      sent: {
        title: i18n('IOrderWorkflow-option-sent', 'Sent')
      },
      canceled: {
        title: i18n('IOrderWorkflow-option-canceled', 'Canceled')
      },
      trash: {
        title: i18n('IOrderWorkflow-option-trash', 'Trash')
      }
    }
  }
});
export var IPromoCodeWorkflow = new Interface({
  // Interface for PromoCodeWorkflow
  name: 'IPromoCodeWorkflow',
  schema: {
    statePropName: 'promoCodeWorkflow',
    workflowStates: {
      draft: {
        title: i18n('IPromoCodeWorkflow-option-draft', 'Draft')
      },
      active: {
        title: i18n('IPromoCodeWorkflow-option-active', 'Active')
      },
      inactive: {
        title: i18n('IPromoCodeWorkflow-option-inactive', 'Inactive')
      },
      trash: {
        title: i18n('IPromoCodeWorkflow-option-trash', 'Trash')
      }
    }
  }
});