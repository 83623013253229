import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, TextField, TextAreaField, IntegerField, DateTimeField, i18n } from 'isomorphic-schema';
var IPromoCode = new Interface({
  name: 'IPromoCode',
  schema: new Schema('PromoCode Schema', {
    discountCode: new TextField({
      label: 'Discount Code',
      placeholder: 'Type here...',
      required: true
    }),
    discountAmount: new TextField({
      label: 'Discount Amount (%)',
      placeholder: 'Type non-decimal value...',
      required: true
    }),
    influencerName: new TextField({
      label: 'Name of Influencer',
      placeholder: 'Type here...' // required: true

    }),
    influencerInstagramAccount: new TextField({
      label: 'Instagram Account of Influencer',
      placeholder: 'Type here...' // required: true

    }),
    notes: new TextAreaField({
      label: 'Notes',
      placeholder: 'Type here...' // required: true

    })
  })
});
export { IPromoCode };