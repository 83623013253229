import { Component } from 'inferno'
import { Container, Col, Row, Button } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { safeGet } from 'safe-utils'
import { PageChrome } from './PageChrome'
import { IApiClient } from '../../interfaces/app'

import '../../widgets/Hero.scss'
import './PageTemplate.scss'
import './page.scss'
import '../onboarding/RegisterStep.scss'

import { utilityToCard, utilityToAtom } from '../../formlib/MobileDocField/utils'
import MobiledocRenderer from '../../widgets/RenderMobiledoc'
import { IMobileDocCardUtil, IMobileDocAtomUtil } from '../../interfaces/formlib'

export default class Page extends Component {
  constructor() {
    super(...arguments)

      const cardUtils = new IMobileDocCardUtil('*')
      const atomUtils = new IMobileDocAtomUtil('*')

    const config = {
      cards: cardUtils.map(util => utilityToCard(util)),
      atoms: atomUtils.map(util => utilityToAtom(util)),
      markups: [],
      sections: [],
      additionalProps: {}
    }

    this.renderer = new MobiledocRenderer(config)
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    try {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/Page/terms-and-conditions`
      })
      
      return data
    }
    catch (e) {
      return undefined
    }
  }

  render() {
    const page = this.props.fetchData
    const mobiledoc = safeGet(() => page.body)
    if (!mobiledoc) return null

    return (
      <Animated key="me" prefix="PageNav">
        <PageChrome className="Fullpage ContentPage">
          <Container fluid>
              <h1>{page.title}</h1>
              {this.renderer.render(mobiledoc)}
          </Container>
        </PageChrome>
      </Animated>
    )
  }
}

