export default class Price {
  static BASE_PRICE = 2499

  static discount(sum, discount) {
    let tmp = sum * (1 - discount/100)
    // Two decimals
    
    tmp = Math.round(tmp)
    return tmp
  }
  
  static toStr(amount) {
    const full = Math.floor(amount / 100)
    const dec = amount - (full * 100)
    return full + '.' + dec + ' EUR'
  }
}