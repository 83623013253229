import { createObjectPrototype, Utility } from 'component-registry';
import assert from 'assert';
import { Permissions } from 'influence-permissions/dist/permissions';
import { PromoCodeWorkflow } from './workflows/PromoCodeWorkflow';
import { IPromoCode } from '../interfaces/PromoCode';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin', 'customerService', 'anonymous'],
  // TODO: Anon should not be alowed to create image
  read: ['owner', 'admin', 'customerService', 'anonymous:promoCodeWorkflow.active'],
  update: ['owner', 'admin', 'customerService'],
  delete: ['owner', 'admin', 'customerService']
};
var PromoCode = createObjectPrototype({
  implements: [IPromoCode],
  extends: [Permissions, PromoCodeWorkflow],
  constructor: function constructor(params) {
    this._type = 'PromoCode';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IPromoCodeWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });
  }
});
export default PromoCode;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'PromoCode',
  getPrototype: function getPrototype() {
    return PromoCode;
  },
  getObject: function getObject(data) {
    data = data || {};
    return new PromoCode(data);
  }
});