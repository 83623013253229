import PageTemplate from './PageTemplate'
import { IApiClient } from '../../interfaces/app'

export default class Page extends PageTemplate {
  static async fetchData ({registry, match, location, router, page = 1}) {
    try {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/Page/${match.params.pageName}`
      })
      return data
    }
    catch (e) {
      return undefined
    }
  }
}
