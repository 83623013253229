import iphone_jpg from './iPhone.jpg'
import {
  BelongsToTag,
  InstagramTag,
} from './tags'
import './iphone_w_tag.scss'

const iphoneTransform = {
  size: [1800, 1800],
  centerpoint: [900, 530],
}

export function IphoneBelongsTo(props) {
  return (
    <div className="ProductPreview">
      <div id="iphone_blue" className="product-container ">
        <img src={iphone_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <BelongsToTag {...props} {...iphoneTransform} />
        </div>
      </div>
    </div>
  )
}

export function IphoneWithInstagram(props) {
  return (
    <div className="ProductPreview">
      <div id="iphone_blue" className="product-container">
        <img src={iphone_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <InstagramTag {...props} {...iphoneTransform} />
        </div>
      </div>
    </div>
  )
}