import { Component } from 'inferno'
import classnames from 'classnames'
import Hls from 'hls.js'
import { Button } from 'inferno-bootstrap'
import './FullVideoPromo.scss'

export class FullVideoPromo extends Component {

  state = {
    isPlaying: false
  }

  componentDidMount() {
    this._initHls()
  }
  
  componentWillUnmount() {
    this.hls && this.hls.destroy()
  }

  render ({src, poster, reverse = false, inverse = false, children}) {
    const { router } = this.context
    return (
      <div ref={(el) => this._el = el} className={classnames("FullVideoPromo", {reverse, inverse})} onClick={this.doPlay}>
        <div className="VideoPromoContent">
          <video ref={(el) => this._videoEl = el} playsinline src={src} poster={poster} />
        </div>
        {!this.state.isPlaying && <div className="body">
          <h2>Why<br />MyMark?</h2>
          <button className="play" />
        </div>}
      </div>
    )
  }

  _initHls() {
    if (this._videoEl) {
      const video = this._videoEl;
      let src = video.src

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        // so we do nothing
        // video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        this.hls = new Hls();
        this.hls.loadSource(src);
        this.hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }
  }

  doPlay = (e) => {
    e && e.preventDefault()
    this._videoEl.play()
    this.setState({
      isPlaying: true
    })
  }
}