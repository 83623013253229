import { Component } from 'inferno'
import { Container } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { ICreateForm } from '../../interfaces/presentation'
import { IApiClient, ISessionManager, IPageManager } from '../../interfaces/app'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import './Create.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export class CreatePage extends Component {

    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'MyMark - Admin',
        description: `MyMark admin interface.`,
        url: FRONTEND_BASE_URI + location.pathname
      })
  
      // Also  triggering update of RoleManager
      return
    }

    render (props, state, context) {
      const EditForm = new ICreateForm(this.context.router.route.match.params.type).Component

      return (
        <Animated key="me" className="EditPage" prefix="PageNav">
          <Container fluid>
              <EditForm {...this.props} />
          </Container>
        </Animated>
      )
    }
}
