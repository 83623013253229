import PageTemplate from './PageTemplate'
import { IApiClient, IPageManager } from '../../interfaces/app'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends PageTemplate {
  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Terms and Conditions',
      description: `MyMark terms and conditions document.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    try {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/Page/terms-and-conditions`
      })
      return data
    }
    catch (e) {
      return undefined
    }
  }
}

