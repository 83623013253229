import airpods_jpg from './airpods.jpeg'
import {
  InitialsTag,
  FullNameTag,
  ContactTag,
  BelongsToTag,
  InstagramTag,
} from './tags'
import './airpods_w_tag.scss'

const airpodsTransform = {
  size: [1800, 1610],
  centerpoint: [1150, 880],
  transform: 'rotateZ(-12deg) rotateY(30deg) rotateX(20deg)',
}

export function AirPodsWithInitials(props) {
  return (
    <div className="ProductPreview">
      <div className="product-container ">
        <img src={airpods_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <InitialsTag {...props} {...airpodsTransform} />
        </div>
      </div>
    </div>
  )
}

export function AirPodsWithFullName(props) {
  return (
    <div className="ProductPreview">
      <div className="product-container ">
        <img src={airpods_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <FullNameTag {...props} {...airpodsTransform} />
        </div>
      </div>
    </div>
  )
}

export function AirPodsWithContact(props) {
  return (
    <div className="ProductPreview">
      <div className="product-container ">
        <img src={airpods_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <ContactTag {...props} {...airpodsTransform} />
        </div>
      </div>
    </div>
  )
}

export function AirPodsBelongsTo(props) {
  return (
    <div className="ProductPreview">
      <div className="product-container ">
        <img src={airpods_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <BelongsToTag {...props} {...airpodsTransform} />
        </div>
      </div>
    </div>
  )
}

export function AirPodsWithInstagram(props) {
  return (
    <div className="ProductPreview">
      <div className="product-container ">
        <img src={airpods_jpg} className="ProductPreview-image" />
        <div className="tag-container">
          <InstagramTag {...props} {...airpodsTransform} />
        </div>
      </div>
    </div>
  )
}