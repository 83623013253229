import { Component } from 'inferno'
import classnames from 'classnames'
import Hls from 'hls.js'
import { Button } from 'inferno-bootstrap'
import './VideoPromo.scss'
export class VideoPromo extends Component {

  _hasPlayed = false

  componentDidMount() {
    this._initHls()
    window.addEventListener('scroll', this.didScroll)
  }
  
  componentWillUnmount() {
    this.hls && this.hls.destroy()
    window.removeEventListener('scroll', this.didScroll)
  }

  render ({src, reverse = false, inverse = false, children}) {
    const { router } = this.context
    return (
      <div ref={(el) => this._el = el} className={classnames("VideoPromo", {reverse, inverse})}>
        <div className="VideoPromoContent">
          <video ref={(el) => this._videoEl = el} playsinline preload muted="true" src={src} />
        </div>
        <div className="body">
          {children}
          <Button color="primary" onClick={() => router.history.push('/product')}>Make your mark</Button>
        </div>
      </div>
    )
  }

  _initHls() {
    if (this._videoEl) {
      const video = this._videoEl;
      let src = video.src

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        // so we do nothing
        // video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        this.hls = new Hls();
        this.hls.loadSource(src);
        this.hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }
  }

  didScroll = () => {
    if (this._debounce) return

    if (!this._hasPlayed) {
      requestAnimationFrame(() => {
        // Start playing when half way up the viewport
        if (window.scrollY > (this._el.offsetTop - window.innerHeight / 2)) {
          this._videoEl.play()
          this._hasPlayed = true
        }
        this._debounce = null
      })
    }
  }
}