import SnippetComponent from '../SnippetComponent'
import classnames from 'classnames'
import Price from '../../../utils/Price'
import { Button } from 'inferno-bootstrap'
import { PageChrome } from '../PageChrome'
import { Progress } from '../OrderConfigurator/ProgressIndicator'
import { IApiClient, IPageManager, IAnalytics } from '../../../interfaces/app'

import {
  Schema,
  TextField,
  EmailField,
  i18n as i18nStr
} from 'isomorphic-schema'
import { FormRows } from 'inferno-formlib'
import { IOrder } from 'mytag-app-entities/lib/interfaces/Order'

import '../page.scss'
import './index.scss'
import { safeGet, safeJoin } from 'safe-utils'

import { IDeserialize, IObjectPrototypeFactory } from 'influence-interfaces/object'

const { STRIPE_PUBLIC_API_KEY } = typeof window !== 'undefined' ? window.__env__ : process.env

const configuratorForm = new Schema('Configurator Form', {
  discountCode: new TextField({
    label: 'Discount Code',
    placeholder: 'Type here...',
  }),
  firstName: new TextField({
    label: 'First Name',
    placeholder: 'Anna',
  }),
  lastName: new TextField({
    label: 'Last Name',
    placeholder: 'Hamilton',
  }),
  email: new EmailField({
    label: 'E-mail',
    placeholder: 'anna@email.com',
  }),
  address_1: new TextField({
    placeholder: 'Hamilton Street 1',
  }),
  address_2: new TextField({
    label: 'Address',
    placeholder: 'Optional...',
  }),
  postalCode: new TextField({
    label: 'Postal Code',
    placeholder: '### ##',
  }),
  postalCity: new TextField({
    label: 'City',
    placeholder: 'Stockholm',
  }),
  country: new TextField({
    label: 'Country',
    placeholder: 'Sweden',
  }),

})

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

function _calcSum(sum, discount) {
  let tmp = sum * (1 - discount/100)
  // Two decimals
  
  tmp = Math.round(tmp * 100)
  return tmp / 100
}

export default class Page extends SnippetComponent {

  constructor (props) {
    super (...arguments)

    const [ order, receiptFinePrint ] = Array.isArray(props.fetchData) && props.fetchData || []

    this.state = {
      formValue: order || {},
      receiptFinePrint: receiptFinePrint,
      validationErrors: undefined,
      submitted: false
    }
  }

  
  static async fetchData ({registry, match, location}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Checkout',
      description: `MyMark order checkout.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    const { data } = await new IApiClient({ registry }).query([
      { URI: `/order/Order/${match.params.pathId}` },
      { URI: `/content/Page/receiptFinePrint` },
    ])
    return data
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchData != this.props.fetchData) {
      const [ order, receiptFinePrint ] = Array.isArray(this.props.fetchData) && this.props.fetchData || []

      if (safeGet(() => this.state.formValue.discountCode != order.discountCode)) {
        this.updatePromo(order, true)
      }
      this.setState({
        formValue: order,
        receiptFinePrint: receiptFinePrint
      })
    }
  }

  updatePromo = async (order, quick) => {
    // TODO: Unpacking content from fetchData is a mess, should be done by a single method not sprinkled in code
    // Throttle calls for
    const timeout = quick ? 0 : 500
    if (this._updatePromoTime) {
      clearTimeout(this._updatePromoTime)
    }

    this._updatePromoTime = setTimeout(async () => {
      this._updatePromoTime = undefined

      if (order && order.discountCode) {
        const { data } = await new IApiClient().query({
          URI: `/order/PromoCode/${order.discountCode}`
        })
        if (data) {
          this.setState({
            promoCode: data
          })
        }
        else {
          this.setState({
            promoCode: undefined
          })
        }
      }
      else {
        this.setState({
          promoCode: undefined
        })
      }
    }, timeout)
  }

  componentDidMount() {
    const [ order, receiptFinePrint ] = Array.isArray(this.props.fetchData) && this.props.fetchData || []
    this.updatePromo(order, true)
  }

  doSubmit = async (e) => {
    e.preventDefault()
    const formData = this.state.formValue
    const errors = IOrder.schema.validate(formData)
    this.setState({
      validationErrors: errors,
      submitted: true
    })

    if (errors) {
      window.scrollTo(0,0)
    }
    else {
      // Note that we can't use .getObject when we already have an ObjectPrototype
      const order = formData

      // Update the order
      const { data: updatedOrder } = await new IApiClient().update({
        URI: `/order/Order/${order._id}`,
        data: order,
        invalidate: '/order/Order'
      }).catch((e) => {
        // TODO: Handle error
      })
      
      // Create payment
      const { data: newPayment } = await new IApiClient().create({
        URI: `/api/stripe`,
        data: updatedOrder,
        invalidate: '/api/stripe'
      }).catch((e) => {
        // TODO: Handle error
      })

      new IAnalytics().interactiveEvent('action', 'to_stripe', 'To Stripe')
      var stripe = window.Stripe(STRIPE_PUBLIC_API_KEY)
      stripe.redirectToCheckout({ sessionId: newPayment.id })
    }
  }

  didChange = (propName, value) => {
    const val = this.state.formValue
    val[propName] = value
    let errors
    if (this.state.submitted) {
      errors = IOrder.schema.validate(val)  
    }
    this.setState({
      formValue: val,
      validationErrors: errors,
    })

    if (propName === 'discountCode') {
      this.updatePromo(val)
    }
    // This is where you would call this.props.onChange
  }

  render () {
      const order = this.state.formValue || {}
      const promo = this.state.promoCode
      const prodConfig = order.productConfiguration || {}

      let text = 'MY'
      if (this.state.formValue) {
        let tmp = [
          safeGet(() => order.firstRow.substr(0,1)),
          safeGet(() => order.secondRow.substr(0,1)),
        ]
        text = safeJoin(tmp, '') || 'MY'
      }
      return (
        <PageChrome className="Fullpage Checkout">
          <Progress step={6} total={6} />
            <div className="ContentContainer">
              <div className="ContentBody">
                <form onAction={this.doSubmit}>
                  <FormRows
                    schema={configuratorForm}
                    selectFields="discountCode"
                    validationErrors={this.state.validationErrors}
                    value={this.state.formValue} onChange={this.didChange} />
                  <div className="OrderReceipt">
                    <h2>Your Order</h2>
                    <img className="ProductSheets" src="/product_sheets.jpg" />
                    <OrderRow>
                      <Text className="TagText" muted>"{safeJoin([prodConfig.firstName, prodConfig.lastName], ' ')}"</Text>
                    </OrderRow>
                    <OrderRow>
                      <Text>300+ tags</Text><RowSum>{Price.toStr(Price.BASE_PRICE)}</RowSum>
                    </OrderRow>
                    {promo && <OrderRow>
                      <Text muted>Discount "{promo.discountCode}"</Text><RowSum>-{promo.discountAmount}%</RowSum>
                    </OrderRow>}
                    <OrderRow>
                      <Text muted>Shipping</Text><RowSum>Free</RowSum>
                    </OrderRow>
                    <OrderRow>
                      <Text>To Pay</Text><RowSum>{Price.toStr(Price.discount(Price.BASE_PRICE, safeGet(() => promo.discountAmount, 0)))}</RowSum>
                    </OrderRow>
                  </div>
                  <FormRows
                    schema={configuratorForm}
                    omitFields="discountCode,order"
                    validationErrors={this.state.validationErrors}
                    value={this.state.formValue} onChange={this.didChange} />
                  <div className="Info"><p>If you aren’t satisfied we will give you a full refund within 30 days.</p></div>
                  
                  <div className="actions">
                    <div className="leading">
                      <h3>{Price.toStr(Price.discount(Price.BASE_PRICE, safeGet(() => promo.discountAmount, 0)))}</h3>
                      <p>Free shipping</p>
                    </div>
                    <Button color="primary" onClick={this.doSubmit}>Pay now</Button>
                  </div>
                </form>
              </div>
            </div>
          </PageChrome>
      )
  }
}

function OrderRow(props) {
  return <div className="OrderRow">{props.children}</div>
}
function Text({className, muted, children}) {
  return <div className={classnames("OrderRow-Text", className, { muted })}>{children}</div>
}
function RowSum(props) {
  return <div className="OrderRow-Sum">{props.children}</div>
}

