import { safeGet } from 'safe-utils'
import SnippetComponent from '../SnippetComponent'
import { IApiClient, IAnalytics, IPageManager, ISessionManager } from '../../../interfaces/app'
import { PageChrome } from '../PageChrome'
import { Hero } from './Hero'
import { ProductPromo } from './ProductPromo'
import { ImagePromo } from './ImagePromo'
import { VideoPromo } from './VideoPromo'
import { FullVideoPromo } from './FullVideoPromo'
import products_w_tag_jpg from '../../../img/frontpage/products_w_tag.jpg'
import girl_promo_jpg from './girl_promo.jpg'
import mission_video_thumb_jpg from './mission_video_thumb.jpg'

import '../../../widgets/Hero.scss'
import '../page.scss'
import './index.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

const SHEETS_PLAYBACK_ID = 'EcdqUf01kc021nzhy7cpvrf9gNy01IG7XVurOVNEcjB5KQ'
const SHEETS_VIDEO_SRC = `https://stream.mux.com/${SHEETS_PLAYBACK_ID}.m3u8`

const MISSON_VIDEO_PLAYBACK_ID = 'Xskj3tT5Mg7bHj00QPfM8M1ljO701D00hIDPfyJ4uuq2Us'
const MISSON_VIDEO_SRC = `https://stream.mux.com/${MISSON_VIDEO_PLAYBACK_ID}.m3u8`

export default class Page extends SnippetComponent {

    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'MyMark - It\'s personal!',
        description: `MyMark privace policy document.`,
        url: FRONTEND_BASE_URI + location.pathname
      })
      const { data } = await new IApiClient({ registry })
        .query({ URI: `/content/Page/productInfo` })
      return data
    }

    componentWillReceiveProps() {
    }

    // navProduct = (e) => {
    //   this.context.router.history.push('/product')
    // }

    render () {
      // const mobiledoc = safeGet(() => this.props.fetchData.body)
      // {mobiledoc && this.renderer.render(mobiledoc)}
      return (
          <PageChrome className="Fullpage FrontPage hasFullcreenHero">
            <Hero />
            <ProductPromo>
              <h2>This is what you get:</h2>
              <div className="left">
                <p>• You get 300+ transparent tags in different sizes and colours</p>
                <p>• They stick to almost any surface</p>
                <p>• Made in Sweden, exclusive quality and low impact</p>
                <p>• Beautifully wrapped, a perfect gift</p>
              </div>
            </ProductPromo>
            <VideoPromo src={SHEETS_VIDEO_SRC} reverse inverse>
              <h2>Buy less,<br/>treasure what you have.</h2>
              <p>Touch up what you have, instead of consuming new stuff.</p>
              <p>Make a mark on things and the world around you.</p>
            </VideoPromo>
            <ImagePromo src={products_w_tag_jpg}>
              <h2>Don't lose those<br />precious things.</h2>
              <p>Never mix up your things with other's again.</p>
              <p>If lost, get them back safely.</p>
            </ImagePromo>
            <ImagePromo src={girl_promo_jpg} reverse>
              <h2>Show who you are<br />on what you love.</h2>
              <p>Personalize the things you can't live without.</p>
              <p>Add uniqueness and style. Add you.</p>
            </ImagePromo>
            <FullVideoPromo src={MISSON_VIDEO_SRC} poster={mission_video_thumb_jpg} />
          </PageChrome>
      )
    }
}

/*
<YouTubePlayer videoId="8TcVD9oLI4c" imageUrl={video_poster_jpg}>We put as much care in to the production of our products as you do to take care of the things you love.</YouTubePlayer>
*/



