import { Adapter, Utility } from 'component-registry'
import { Component, createPortal } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'

import { widgets, FormRows } from 'inferno-formlib'
//import 'inferno-formlib/lib/widgets/FormRow'
//import 'inferno-formlib/lib/widgets/InputField'
//import 'inferno-formlib/lib/widgets/DateTimeField'
import { Manager, Target, Popper, Arrow, Travel } from 'inferno-popper'

import {
    Button,
    ButtonGroup,
    Nav,
    NavDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row,
    Form
} from 'inferno-bootstrap'

import PrintTags from './PrintTags'
import PrintAddress from './PrintAddress'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { calculateToolbarBoundary } from '../../widgets/RichTextWidget'

import { IOrder } from 'mytag-app-entities/lib/interfaces/Order'
import { ICreateForm, IEditForm, INotificationManager } from '../../interfaces/presentation'
import { ISessionManager, IApiClient } from '../../interfaces/app'
import { IOrderWorkflow } from 'mytag-app-entities/lib/interfaces/workflows'

import '../../pages/admin/Create.scss'
import '../../pages/admin/Edit.scss'
import './EditForm.scss'

const { ActionBar } = widgets


const workflowStates = IOrderWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.orderWorkflow = newState
  new IApiClient().update({
    URI: `/order/Order/${obj._id}`,
    data: obj,
    invalidate: `/order/Order`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowStatus ({ post, isOpen, doOpen, doClose }) {
  return (
    <Nav className="WorkflowStatus">
      <NavDropdown isOpen={isOpen} toggle={() => { isOpen ? doClose() : doOpen()}}>
        <DropdownToggle nav caret className="status">
          {safeGet(() => post._workflows.orderWorkflow)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{i18n('WorkflowAction-updateStatus', 'Change Status')}</DropdownItem>
          {Object.keys(workflowStates).map((key) => {
            // Don't return the current state since we don't want to transition to ourself
            const isActive = (post._workflows && post._workflows.orderWorkflow) === key
            if (isActive) return null
    
            return (
              <DropdownItem onClick={(e) => doChangeWorkflowState(post, key, doClose)}>
                {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </NavDropdown>
    </Nav>
  )
}

class EditForm extends Component {

    constructor (props, context) {
        super(...arguments)

        this.state = {
            // TODO: We should not need to pass role manager when creating Order
            value: props.value || new IObjectPrototypeFactory('Order').getObject({
              _workflows: { orderWorkflow: 'draft' }
            }),
            validationErrors: undefined,
            actionBarBoundary: {top: 0, bottom: 0},
            isDirty: false,
            popoverWorkflowStatusOpen: false,
            popoverTimedPublishingOpen: false,
            showCloseWarningModal: false,

            showPrintableTags: false,
            showPrintableAddress: false
        }

        this.calculateToolbarBoundary = calculateToolbarBoundary.bind(this)        
    }

    

    componentDidMount () {
        this.calculateToolbarBoundary();

        this.printableDiv = document.getElementById('printable');
    }

    doOpenWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: true
      });
    }

    doCloseWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: false
      });
    }

    didUpdate = (propName, value) => {
        const isNewValue = JSON.stringify(value) !== JSON.stringify(this.state.value[propName])
        const isDirty = this.state.isDirty || isNewValue
        
        if (isNewValue) {
          const newVal = this.state.value
          newVal[propName] = value
  
          this.setState({
              value: newVal,
              isDirty
          })
        }
        this.calculateToolbarBoundary()
    }
    
    didUpdateRichText = (mobiledoc) => {
        this.didUpdate('body', mobiledoc)
        this.calculateToolbarBoundary()
    }

    doClose = (e) => {
      let forceClose
      if (e.force) {
        forceClose = true
      }

      if (e.event) {
        // setting event param
        e = e.event
      }

      e.preventDefault()
      
      if (this.state.isDirty && !forceClose) {
        this.setState({
          showCloseWarningModal: true
        })
      }
      else {
        const { type } = this.props.match.params
        this.context.router.history.push(`/admin/${type}`)
      }
    }

    doSave = ({ publish }) => {
        this.doCloseWorkflowStatus()
        const { type } = this.props.match.params

        let post = this.state.value

        if (post._id) {
            return new IApiClient().update({
                URI: `/order/Order/${this.state.value._id}`,
                data: post,
                invalidate: `/order/Order`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.setState({
                  value: data,
                  isDirty: false
                })
            })
        } else {
            return new IApiClient().create({
                URI: `/order/Order`,
                data: post,
                invalidate: `/order/Order`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.context.router.history.push(`/admin/${type}/${data._id}`)
            })
        }
    }

    doPrint = (e) => {
      e.preventDefault()
      this.setState({
        showPrintableTags: true
      })
    }

    didPrintTags = () => {
      this.setState({
        showPrintableTags: false
      })
    }

    doPrintAddress = (e) => {
      e.preventDefault()
      this.setState({
        showPrintableAddress: true
      })
    }

    didPrintAddress = () => {
      this.setState({
        showPrintableAddress: false
      })
    }

    renderCloseWarningModal () {
      return (
        <Modal isOpen={this.state.showCloseWarningModal} toggle={() => this.setState({ showCloseWarningModal: false })}>
          <ModalHeader>WARNING! Unsaved changes</ModalHeader>
          <ModalBody>
            <p>You have unsaved changes, are you sure you want to close without saving?</p>
          </ModalBody>
          <ModalFooter className="ActionBarContent">            
              <Button color="primary"
                onClick={(e) => {e.preventDefault(); this.setState({ showCloseWarningModal: false })}}>Cancel</Button>

              <i>or</i>
              
              <Button color="danger" outline
                onClick={(e) => {this.doClose({ event: e, force: true })}}>Close</Button>
              
              <i>or</i>
              
              <Button color="link"
                onClick={(e) => {this.doSave({}).then(() => this.doClose({ event: e }))}}>Save & close</Button>
          </ModalFooter>
        </Modal>
      )
    }

    renderSaveActions () {
      switch (this.state.value._workflows.orderWorkflow) {  
        case 'draft':
          return (
            <div className="ActionBarContent-MainActions">
              <Button color="success" type="submit">Save to draft</Button>
            </div>
          )
        default:
          return (
            <div className="ActionBarContent-MainActions">
              <Button color="success" type="submit">Update</Button>
            </div>
          )
      }
    }

    renderSecondaryActions () {

      return (
        <div className="ActionBarContent-SecondaryActions">
          {/*  <Button className="PrintBtn" color="primary" outline size="sm" onClick={this.doPrint}>Print tags</Button> */}
          <Button className="PrintBtn" color="primary" outline size="sm" onClick={this.doPrintAddress}>Print address</Button>
          <WorkflowStatus post={this.state.value} isOpen={this.state.popoverWorkflowStatusOpen}
              doOpen={this.doOpenWorkflowStatus}
              doClose={this.doCloseWorkflowStatus} />
        </div>
      )
    }

    render () {
        const isCreated = (this.state.value._id !== undefined)
        return <>
            <ButtonGroup className="Actions form-header">
                <Button color="link" className="CloseButton" onClick={this.doClose}>X Close</Button>
            </ButtonGroup>
            <div ref={(e) => this._containerEl = e}>
                {this.state.modalWidget}
                {!isCreated && <h2>Create</h2>}
                {isCreated && <h3>Order checkout URL: <a target="_blank" href={`/order/${this.state.value._pathId}/checkout`}>{`/order/${this.state.value._pathId}/checkout`}</a></h3>}
                <Form onSubmit={(e) => {e.preventDefault(); this.doSave({})}} className='IEditItem'>
                    <FormRows
                      schema={IOrder.schema}
                      validationErrors={this.state.validationErrors}
                      value={this.state.value} onChange={this.didUpdate} />
                    <div className="ActionBar" boundary={this.state.actionBarBoundary}>
                        {/* Adding a key for ActionBarContent forces rerender when publish state is changed.
                            This makes sure that floating bar and sticky bar i in sync */}
                        <div key={this.state.value._workflows.orderWorkflow} className="ActionBarContent">
                            {this.renderSaveActions()}
                            {this.renderSecondaryActions()}
                        </div>
                    </div>
                </Form>
                {this.renderCloseWarningModal()}
                {this.state.showPrintableTags && createPortal(<PrintTags order={this.state.value} onDone={this.didPrintTags} />, this.printableDiv)}
                {this.state.showPrintableAddress && createPortal(<PrintAddress order={this.state.value} onDone={this.didPrintAddress} />, this.printableDiv)}
            </div>
        </>
    }
}

new Adapter({
  implements: IEditForm,
  adapts: IOrder,
  Component: EditForm
})

new Utility({
  implements: ICreateForm,
  name: 'Order',
  Component: EditForm
})
