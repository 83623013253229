import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, TextField, IntegerField, ListField, ObjectField, DateTimeField, i18n } from 'isomorphic-schema';
var orderRowSchema = new Schema('Order Row Schema', {
  itemDescription: new TextField({
    label: 'Item Description',
    placeholder: 'Type here...',
    required: true,
    readOnly: true
  }),
  sku: new TextField({
    label: 'SKU',
    placeholder: 'Type here...',
    required: true,
    readOnly: true
  }),
  itemPriceInOrderCurrencyAsInt: new IntegerField({
    label: 'Item Price',
    placeholder: 'Type here...',
    help: 'Price includes two decimals without decimal point',
    required: true,
    readOnly: true
  }),
  qty: new IntegerField({
    label: 'Qty',
    placeholder: 'Type here...',
    required: true,
    readOnly: true
  })
});
var orderSchema = new Schema('Order Schema', {
  orderDate: new DateTimeField({
    label: 'Order Date',
    readOnly: true
  }),
  orderCurrency: new TextField({
    label: 'Order Currency',
    placeholder: 'Type here...',
    help: 'EUR, SEK, USD etc.',
    required: true,
    readOnly: true
  }),
  orderRows: new ListField({
    label: 'Order Rows',
    valueType: new ObjectField({
      schema: orderRowSchema
    }),
    readOnly: true
  })
});
var productConfigurationSchema = new Schema('Product Configuration Schema', {
  initials: new TextField({
    label: 'Initials',
    placeholder: 'AH',
    maxLength: 2,
    help: 'Two letters (uppercase)',
    required: true
  }),
  firstName: new TextField({
    label: 'First Name',
    placeholder: 'Anna',
    maxLength: 17,
    help: 'First name',
    required: true
  }),
  lastName: new TextField({
    label: 'Last Name',
    placeholder: 'Hamilton',
    maxLength: 17,
    help: 'Last name',
    required: true
  }),
  contactInitials: new TextField({
    label: 'Initials',
    placeholder: 'AH',
    maxLength: 2,
    help: 'Two letters (uppercase)',
    required: true
  }),
  contactPhone: new TextField({
    label: 'Contact',
    placeholder: '###-### ## ##',
    maxLength: 20,
    help: 'Phone number',
    required: true
  }),
  belongsToFirstName: new TextField({
    label: 'First Name',
    placeholder: 'Anna',
    maxLength: 17,
    help: 'First name',
    required: true
  }),
  instagram: new TextField({
    label: 'Instagram',
    placeholder: '@getmymark',
    maxLength: 17,
    help: 'Instagram handle (include @) or other',
    required: true
  })
});
var IOrder = new Interface({
  name: 'IOrder',
  schema: new Schema('Complete Order Schema', {
    discountCode: new TextField({
      label: 'Discount Code',
      placeholder: 'Type here...' // required: true

    }),
    firstName: new TextField({
      label: 'First Name',
      placeholder: 'Anna',
      required: true
    }),
    lastName: new TextField({
      label: 'Last Name',
      placeholder: 'Hamilton',
      required: true
    }),
    email: new TextField({
      label: 'Email',
      placeholder: 'anna@email.com',
      required: true
    }),
    country: new TextField({
      label: 'Country',
      placeholder: 'Sweden',
      required: true
    }),
    address_1: new TextField({
      label: 'Address Row 1',
      placeholder: 'Hamilton Street 1',
      required: true
    }),
    address_2: new TextField({
      label: 'Address Row 2',
      placeholder: 'Type here...' // required: true

    }),
    postalCode: new TextField({
      label: 'Postal Code',
      placeholder: '111 11',
      required: true
    }),
    postalCity: new TextField({
      label: 'City',
      placeholder: 'Stockholm',
      required: true
    }),
    order: new ObjectField({
      schema: orderSchema
    }),
    productConfiguration: new ObjectField({
      schema: productConfigurationSchema
    })
  })
});
export { IOrder, orderSchema, productConfigurationSchema };