import './tags.scss'

/**
 * 
 * Instructions:
 * 
 * Change the transforms closest to the tag content to center
 * the tag (-0.5*W, -0.5*h).
 */

export function InitialsTag({ initials = 'AH', size = [], centerpoint = [], transform }) {
  const [w, h] = size
  const [x, y] = centerpoint
  const svgTransform = `translate(${x} ${y})`
  return (
      <svg
        width={w + "px"}
        height={h + "px"}
        viewBox={"0 0 " + w + " " + h}
        style={{
          "transform": transform,
        }}>
          <g transform={svgTransform} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-105, -100)">
                  <g id="tag_initials">
                      <path d="M92.6380575,-2.32157349e-15 L117.361943,2.32157349e-15 C141.121871,-2.04305465e-15 156.036791,3.71302445 169.073828,10.6853082 C182.110865,17.6575919 192.342408,27.8891348 199.314692,40.9261719 C206.286976,53.9632089 210,68.8781285 210,92.6380575 L210,107.361943 C210,131.121871 206.286976,146.036791 199.314692,159.073828 C192.342408,172.110865 182.110865,182.342408 169.073828,189.314692 C156.036791,196.286976 141.121871,200 117.361943,200 L92.6380575,200 C68.8781285,200 53.9632089,196.286976 40.9261719,189.314692 C27.8891348,182.342408 17.6575919,172.110865 10.6853082,159.073828 C3.71302445,146.036791 1.36203643e-15,131.121871 -1.54771566e-15,107.361943 L1.54771566e-15,92.6380575 C-1.36203643e-15,68.8781285 3.71302445,53.9632089 10.6853082,40.9261719 C17.6575919,27.8891348 27.8891348,17.6575919 40.9261719,10.6853082 C53.9632089,3.71302445 68.8781285,2.04305465e-15 92.6380575,-2.32157349e-15 Z" id="tag" stroke="#C2C2C2" fill="#D8D8D8" opacity="0.1"></path>
                      <text id="initials" font-family="Gilroy-Medium, Gilroy" font-size="70" font-weight="400" fill="#0078A8">
                          <tspan text-anchor="middle" x="105" y="124">{initials}</tspan>
                      </text>
                  </g>
              </g>
          </g>
      </svg>
  )
}

export function FullNameTag({ firstName = 'Anna', lastName = 'Hamilton', size = [], centerpoint = [], transform }) {
  const [w, h] = size
  const [x, y] = centerpoint
  const svgTransform = `translate(${x} ${y})`
  return (
      <svg
          width={w + "px"}
          height={h + "px"}
          viewBox={"0 0 " + w + " " + h}
          style={{
            "transform": transform,
          }}>
          <g transform={svgTransform} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-270, -100)">
                  <g id="tag_full_name">
                      <rect id="tag" stroke="#C2C2C2" fill="#D8D8D8" opacity="0.1" x="0" y="0" width="540" height="200" rx="20"></rect>
                      <text id="firstName" font-family="Gilroy-Medium, Gilroy" font-size="50" font-weight="400" fill="#000000">
                          <tspan text-anchor="middle" x="270" y="89">{firstName}</tspan>
                      </text>
                      <text id="lastName" font-family="Gilroy-Medium, Gilroy" font-size="50" font-weight="400" fill="#000000">
                          <tspan text-anchor="middle" x="270" y="144">{lastName}</tspan>
                      </text>
                  </g>
              </g>
          </g>
      </svg>
  )
}

export function ContactTag({ contactInitials = 'AH', contactPhone = '070-123 45 67', size = [], centerpoint = [], transform }) {
  const [w, h] = size
  const [x, y] = centerpoint
  const svgTransform = `translate(${x} ${y})`
  return (
      <svg
        width={w + "px"}
        height={h + "px"}
        viewBox={"0 0 " + w + " " + h}
        style={{
          "transform": transform,
        }}>
          <g transform={svgTransform} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-270, -100)">
                  <g id="tag_contact">
                      <rect id="tag" stroke="#C2C2C2" fill="#D8D8D8" opacity="0.1" x="0" y="1" width="540" height="200" rx="20"></rect>
                      <text id="initials" font-family="Gilroy-Medium, Gilroy" font-size="130" font-weight="400" fill="#000000">
                          <tspan text-anchor="middle" x="270" y="121">{contactInitials}</tspan>
                      </text>
                      <text id="phone" font-family="Gilroy-Medium, Gilroy" font-size="40" font-weight="400" fill="#000000">
                          <tspan text-anchor="middle" x="270" y="176">{contactPhone}</tspan>
                      </text>
                  </g>
              </g>
          </g>
      </svg>
  )
}

export function BelongsToTag({ belongsToFirstName = 'Anna', size = [], centerpoint = [], transform }) {
  const [w, h] = size
  const [x, y] = centerpoint
  const svgTransform = `translate(${x} ${y})`
  return (
      <svg
        width={w + "px"}
        height={h + "px"}
        viewBox={"0 0 " + w + " " + h}
        style={{
          "transform": transform,
        }}>
          <g transform={svgTransform} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-640, -100)">
                  <g id="tag_belongs_to">
                      <rect id="tag" stroke="#C2C2C2" fill="#D8D8D8" opacity="0.05" x="0" y="0" width="1280" height="200" rx="20"></rect>
                      <text id="belongsTo">
                          <tspan text-anchor="middle" x="640" y="128">This belongs to {belongsToFirstName}</tspan>
                      </text>
                  </g>
              </g>
          </g>
      </svg>
  )
}

export function InstagramTag({ instagram = '@getmymark', size = [], centerpoint = [], transform }) {
  const [w, h] = size
  const [x, y] = centerpoint
  const svgTransform = `translate(${x} ${y})`
  return (
      <svg
        width={w + "px"}
        height={h + "px"}
        viewBox={"0 0 " + w + " " + h}
        style={{
          "transform": transform,
        }}>
          <g transform={svgTransform} stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-440, -100)">
                  <g id="tag_instagram">
                      <rect id="tag" stroke="#C2C2C2" fill="#D8D8D8" opacity="0.05" x="0" y="0" width="880" height="200" rx="20"></rect>
                      <text id="instagram">
                          <tspan text-anchor="middle" x="440" y="117">{instagram}</tspan>
                      </text>
                  </g>
              </g>
          </g>
      </svg>
  )
}
