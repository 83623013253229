import { Link } from 'inferno-router'
import { LogoutMenu } from './MainMenu'
import logo_svg from '../img/logo/logo-colored.svg'
import logo_payoff_svg from '../img/logo/logo-payoff-colored.svg'
import './SitePageHeader.scss'

export default function SitePageHeader ({ withPayoff }) {
  return (
    <PageHeaderContainer>
      {withPayoff && <WithPayoff />}
      {!withPayoff && <JustLogo />}
      <LogoutMenu />
    </PageHeaderContainer>
  )
}

function JustLogo() {
  return (
    <Link to="/" id="Page-Logo">
      <img src={logo_svg} />
    </Link>
  )
}

function WithPayoff() {
  return (
    <div className="LogoStripe">
      <Link to="/" id="Page-Logo">
        <img src={logo_payoff_svg} />
      </Link>
    </div>
  )
}

function PageHeaderContainer (props) {
  return (
    <div className="PageHeaderContainer">
      {props.children}
    </div>
  )
}
