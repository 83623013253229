import SnippetComponent from '../SnippetComponent'
import { i18n } from '../../../i18n'
import classnames from 'classnames'
import Price from '../../../utils/Price'
import { PageChrome } from '../PageChrome'

import { IApiClient, IPageManager } from '../../../interfaces/app'

import '../../../widgets/Hero.scss'
import '../../../widgets/Hero.scss'
import '../page.scss'
import './index.scss'
import { safeGet, safeJoin } from 'safe-utils'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends SnippetComponent {
  
  static async fetchData ({registry, match, location}) {
    new IPageManager({ registry }).setMetaData({
      title: 'MyMark - Order Receipt',
      description: `MyMark order receipt.`,
      url: FRONTEND_BASE_URI + location.pathname
    })

    const { data } = await new IApiClient({ registry }).query([
      { URI: `/order/Order/${match.params.pathId}` },
      { URI: `/content/Page/receiptFinePrint` },
    ])
    return data
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchData != this.props.fetchData) {
      const [ order, receiptFinePrint ] = Array.isArray(this.props.fetchData) && this.props.fetchData || []
      if (safeGet(() => this.state.formValue.discountCode != order.discountCode)) {
        this.updatePromo(order, true)
      }
    }
  }

  componentDidMount() {
    const [ order, receiptFinePrint ] = Array.isArray(this.props.fetchData) && this.props.fetchData || []
    this.updatePromo(order, true)
  }

  updatePromo = async (order, quick) => {
    // Throttle calls for
    const timeout = quick ? 0 : 500
    if (this._updatePromoTime) {
      clearTimeout(this._updatePromoTime)
    }

    this._updatePromoTime = setTimeout(async () => {
      this._updatePromoTime = undefined

      if (order && order.discountCode) {
        const { data } = await new IApiClient().query({
          URI: `/order/PromoCode/${order.discountCode}`
        })
        if (data) {
          this.setState({
            promoCode: data
          })
        }
      }
    }, timeout)
  }


  render () {
    let [ order, receiptFinePrint ] = Array.isArray(this.props.fetchData) && this.props.fetchData || []
    order = order || {}
    const mobiledoc = safeGet(() => receiptFinePrint.body)
    
    const promo = this.state && this.state.promoCode

    const shippingStatus = safeGet(() => i18n('orderReceipt-' + order._workflows.orderWorkflow))

    return (
        <PageChrome className="Fullpage Receipt">
          <div className="ContentContainer">
            <div className="ContentBody">
                <div className="OrderReceipt">
                <h2>Your Receipt</h2>
                  <img className="ProductSheets" src="/product_sheets.jpg" />
                  <OrderRow>
                    <Text className="TagText" muted>"{safeJoin([order.firstName, order.lastName], '  ')}"</Text>
                  </OrderRow>
                  <OrderRow>
                    <Text>300+ tags</Text><RowSum>{Price.toStr(Price.BASE_PRICE)}</RowSum>
                  </OrderRow>
                  {promo && <OrderRow>
                    <Text muted>Discount "{promo.discountCode}"</Text><RowSum>-{promo.discountAmount}%</RowSum>
                  </OrderRow>}
                  <OrderRow>
                    <Text muted>Shipping</Text><RowSum>Free</RowSum>
                  </OrderRow>
                  <OrderRow>
                    <Text>Payed Amount</Text><RowSum>{Price.toStr(Price.discount(Price.BASE_PRICE, safeGet(() => promo.discountAmount, 0)))}</RowSum>
                  </OrderRow>
                </div>
                <h2>Shipping Address</h2>
                <AddressRow>{safeJoin([order.firstName, order.lastName], ' ')}</AddressRow>
                <AddressRow>{order.address_1}</AddressRow>
                {order.address_2 && <AddressRow>{order.address_2}</AddressRow>}
                <AddressRow><i>{order.postalCode}</i>{order.postalCity}</AddressRow>
                <AddressRow>{order.country}</AddressRow>

                <div className="ShippingStatus">Status: {shippingStatus}</div>

                <div className="Info">{mobiledoc && this.renderer.render(mobiledoc)}</div>
            </div>
          </div>
        </PageChrome>
      )
  }
}

function OrderRow(props) {
  return <div className="OrderRow">{props.children}</div>
}
function Text({className, muted, children}) {
  return <div className={classnames("OrderRow-Text", className, { muted })}>{children}</div>
}
function RowSum(props) {
  return <div className="OrderRow-Sum">{props.children}</div>
}
function AddressRow(props) {
  return <div className="AddressRow">{props.children}</div>
}
